import React, { Component } from 'react';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { s3StaticPath, getDemoVideoLink } from '../../helper/helperFunctions';
import { MdLocalMovies } from 'react-icons/md';
import { IoMdPlay } from 'react-icons/io';
import { FaHandHoldingHeart, FaQuestion } from 'react-icons/fa';


import VideoIcon from './VideoIcon'
import MainClients from './MainClients';
class KeepsakeVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      activeTabNo: 1,
      activeVideo: 1,
      bucket: 'https://grouptravelvideos.s3.amazonaws.com/',
      videoUrl: 'global/2016_DC_demo.mp4',
      location: 'Washington DC'
    };
    this.handleHover = this.handleHover.bind(this);
    this.activeTabFunction = this.activeTabFunction.bind(this);
  }
  activeTabFunction(tab, name, url = null, location = null) {
    if (name === 'activeVideo') {
      if (this.state.activeVideo !== tab) {
        this.setState({ [name]: tab, videoUrl: url, location: location },);
      }
    } else {
      if (this.state.activeTabNo !== tab) {
        this.setState({ [name]: tab });
      }
    }
    return null;
  }

  handleVideo() {
    let demoVideo = document.getElementById("demoVideo");
    demoVideo.play();
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };
    const posters = {
      'Washington DC': 'washington-dc.jpg',
      'Chicago': 'Chicago.jpg',
      'New York City': 'New-York-City.jpg',
      'Orlando': 'Orlando.jpg',
      'Italy': 'Italy.jpg',
    }

    let is_itk = false;

    if (window.location.href.indexOf("itk") > -1){

        is_itk = true;
    }else{
        is_itk = false;
        console.log("else part run");
    }
    console.log("is_itk===>", is_itk);
    return (
      <React.Fragment>
        <section id="keepsake_video" className="section">
          <h2 className="panel_heading pollyBold">We create a storytelling video to keep for a lifetime!</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-4 mt-xlg">
                <div className=" mt-md">
                  <img src={s3StaticPath('img/keepsake_video.png')} alt="Keepsake Storytelling Video" />
                </div>
              </div>
              <div className="col-md-8 mt-xlg">
                <div className="mt-md">
                  <p className="text_24 text-center pollyRegular">
                    We use the photos everyone shares on your trip and combine them with non-copyright music and titles you may customize.
                  </p>
                  <p className="text_24 mt-xxlg text-center pollyRegular">
                    View and download the video with our app or on this website to enjoy for a lifetime.
                  </p>
                  <p className="text_24 mt-xxlg text-center pollyRegular">
                    You can store it on any media of your choice: computer, flash drive, external hard drive, cloud, and/or phone. It is yours to keep and enjoy for years to come!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="uploaderTabs" className="section bg_alter sampleVideos">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tabsContainer">
                  <div className="tabsHeader">
                    <div className="flexElem respFlex alignCenter">
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(1, 'activeTabNo')}

                          className={"tabBtn text_16 pollyBold " + (this.state.activeTabNo === 1 ? "activeTab red_gradient" : "medium_blue_gradient")}><MdLocalMovies />&nbsp;Sample Videos</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(2, 'activeTabNo')}
                          className={"tabBtn text_16 pollyBold " + (this.state.activeTabNo === 2 ? "activeTab red_gradient" : "medium_blue_gradient")}><FaQuestion />&nbsp;How it works</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(3, 'activeTabNo')}
                          className={"tabBtn text_16 pollyBold " + (this.state.activeTabNo === 3 ? "activeTab red_gradient" : "medium_blue_gradient")} ><FaHandHoldingHeart />&nbsp;Benefits</a>
                      </div>
                      <div>
                        <a href="#/" type='button'
                          onClick={() => this.activeTabFunction(4, 'activeTabNo')}
                          className={"tabBtn text_16 pollyBold " + (this.state.activeTabNo === 4 ? "activeTab red_gradient" : "medium_blue_gradient")}><IoMdPlay />&nbsp;App Demo</a>
                      </div>
                    </div>
                  </div>

                  <div className="tabsContent">
                    {this.state.activeTabNo === 1 &&
                      <div className="contentContainer">
                        <div className="flexElem respFlex">
                          <div>
                            <div className="text_18 text-left pollyBold">Student Travel</div>

                            <ul className="sampleVideosList">
                              <li onClick={() => this.activeTabFunction(1, 'activeVideo', 'global/2016_DC_demo.mp4', 'Washington DC')}>
                                <VideoIcon iconClass={this.state.activeVideo === 1 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 1 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span className='pollyRegular'> Washington DC</span>
                              </li>
                              <li onClick={() => this.activeTabFunction(2, 'activeVideo', 'global/2016_NY_demo.mp4', 'New York City')}>
                                <VideoIcon iconClass={this.state.activeVideo === 2 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 2 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span className='pollyRegular'> New York City</span>
                              </li>
                              <li onClick={() => this.activeTabFunction(3, 'activeVideo', 'global/2016_orlando_demo.mp4', 'Orlando')}>
                                <VideoIcon iconClass={this.state.activeVideo === 3 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 3 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span className='pollyRegular'> Orlando</span>
                              </li>
                              <li onClick={() => this.activeTabFunction(4, 'activeVideo', 'global/2016_chicago_demo.mp4', 'Chicago')}>
                                <VideoIcon iconClass={this.state.activeVideo === 4 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 4 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span className='pollyRegular'> Chicago</span>
                              </li>
                            </ul>

                            <div className="text_18 mt-xlg text-left pollyBold">Senior Travel</div>

                            <ul className="sampleVideosList">
                              <li onClick={() => this.activeTabFunction(5, 'activeVideo', 'global/2016_senior_italy_demo.mp4', 'Italy')}>
                                <VideoIcon iconClass={this.state.activeVideo === 5 ? "fa-spin" : ""} svgColor={this.state.activeVideo === 5 ? "url('#video-gradient')" : "currentColor"} svgID="video-gradient" />
                                <span className='pollyRegular'> Italy</span>
                              </li>
                            </ul>
                          </div>

                          <div id="video" style={{ border: "none" }}>
                            <div className="vidContainer">
                              <div className="section_heading mt-none text-center pollyBold">{this.state.location}</div>

                              <div className="video_placeholder mb-none">
                                <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls src={this.state.bucket + this.state.videoUrl} type="video/mp4" poster={this.state.bucket + `static/img/${posters[this.state.location]}`}></video>
                                <div className="video_play blue_gradient hover_dark" onClick={this.handleVideo}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 2 &&
                      <div className="contentContainer">
                        <div className="flexElem">
                          <div>
                            <span className="howItWorksNumber purple_gradient">1</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                              We provide your group with a username &amp; password to login to our app and website to upload trip photos.
                                    </div>
                            <div className="text_14 mt-xs pollyRegular">
                              We can also send you a camera or memory card.
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">2</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                              Your group uploads photos with our app or website while and/or after you travel.
                                    </div>
                            <div className="text_14 mt-xs pollyRegular">
                              You return any of our cameras or cards back in the prepaid mailer we provide.
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">3</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                              We edit your photos and repost them to the app and website. Then we create your video from the edited photos.
                                    </div>
                            <div className="text_14 mt-xs pollyRegular">
                              Your photos can only be accessed with your group’s login.
                                    </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_gradient">4</span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                              {/* We ship your DVDs to you and make your video available to stream on our app &amp; website. */}
                              We make your keepsake video available to stream on our app and download &amp; save on our website.
                                    </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 3 &&
                      <div className="contentContainer">
                        <div className="flexElem">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                              New FREE Mobile App:
                                  </div>
                            <div className="text_14 mt-xs pollyRegular">
                              Allows you to upload and share photos while you travel.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                              Live Video Clips &amp; Performance Music:
                                  </div>
                            <div className="text_14 mt-xs pollyRegular">
                              You can include these in your videos for FREE.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                              Facebook Sharing:
                                  </div>
                            <div className="text_14 mt-xs pollyRegular">
                              You can easily share photos &amp; your highlight video on Facebook from the app or GroupTravelVideos.com photo gallery.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                              Video Downloads:
                                  </div>
                            <div className="text_14 mt-xs pollyRegular">
                              Videos can be downloaded from the app or GroupTravelVideos.com gallery for offline viewing.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                              Photo Editing:
                                  </div>
                            <div className="text_14 mt-xs pollyRegular">
                              Our editors go through each photo to remove unusable photos, do color correction, &amp; cropping.
                                  </div>
                          </div>
                        </div>

                        <div className="flexElem mt-xlg">
                          <div>
                            <span className="howItWorksNumber purple_blue_gradient"></span>
                          </div>
                          <div>
                            <div className="text_16 howItWorksPoints pollyBold">
                                Keepsake Video:
                                  </div>
                            <div className="text_14 mt-xs pollyRegular">
                              {/* Each traveler receives a video on DVD to keep, share, &amp; preserve your memories for years to come! */}
                              Each traveler is able to stream, cast, share and download the video to preserve their memories for years to come!
                                  </div>
                          </div>
                        </div>
                      </div>
                    }

                    {this.state.activeTabNo === 4 &&
                      <div className="contentContainer">
                        <div className="video_placeholder mb-none">
                          {/* <img src={s3StaticPath('img/video_placeholder.jpg')} className="block" alt="Video placeholder" /> */}
                          <video onPlay={() => { $(".video_placeholder .video_play").hide() }} onPause={() => { $(".video_placeholder .video_play").show() }} id="demoVideo" controls type="video/mp4"

                                src={s3StaticPath('gtv_appDemo_apple.mp4')} type="video/mp4" poster={s3StaticPath('img/gtv_app_video.jpg')}>
                            {/* // src={is_itk ? s3VideoPath('ITK_App_Demo.mp4') : s3StaticPath('gtv_appDemo_apple.mp4')} > */}


                          </video>

                          <div className="video_play blue_gradient hover_dark" onClick={this.handleVideo}></div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default KeepsakeVideo;
