export const adminConstants = {
	//Auth
	LOGIN_ADMIN_REQUEST: 'LOGIN_ADMIN_REQUEST',
	LOGIN_ADMIN_SUCCESS: 'LOGIN_ADMIN_SUCCESS',
	LOGIN_ADMIN_FAILURE: 'LOGIN_ADMIN_FAILURE',

	LOGOUT_ADMIN_REQUEST: 'LOGOUT_ADMIN_REQUEST',
	LOGOUT_ADMIN_SUCCESS: 'LOGOUT_ADMIN_SUCCESS',
	LOGOUT_ADMIN_FAILURE: 'LOGOUT_ADMIN_FAILURE',

	GET_MENU_DASHBOARD_REQUEST: 'GET_MENU_DASHBOARD_REQUEST',
	GET_MENU_DASHBOARD_SUCCESS: 'GET_MENU_DASHBOARD_SUCCESS',
	GET_MENU_DASHBOARD_FAILURE: 'GET_MENU_DASHBOARD_FAILURE',

	GET_MENU_REQUEST: 'GET_MENU_REQUEST',
	GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
	GET_MENU_FAILURE: 'GET_MENU_FAILURE',

	GET_DASHBOARD_REQUEST: 'GET_DASHBOARD_REQUEST',
	GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
	GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',

	PAGINATION_USERS_REQUEST: 'PAGINATION_USERS_REQUEST',
	PAGINATION_USERS_SUCCESS: 'PAGINATION_USERS_SUCCESS',
	PAGINATION_USERS_FAILURE: 'PAGINATION_USERS_FAILURE',

	STORE_USER_REQUEST: 'STORE_USER_REQUEST',
	STORE_USER_SUCCESS: 'STORE_USER_SUCCESS',
	STORE_USER_FAILURE: 'STORE_USER_FAILURE',

	GET_USER_REQUEST: 'GET_USER_REQUEST',
	GET_USER_SUCCESS: 'GET_USER_SUCCESS',
	GET_USER_FAILURE: 'GET_USER_FAILURE',

	EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
	EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
	EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

	DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
	DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
	DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

	GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
	GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
	GET_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',

	GET_AGENCY_ROLES_REQUEST: 'GET_AGENCY_ROLES_REQUEST',
	GET_AGENCY_ROLES_SUCCESS: 'GET_AGENCY_ROLES_SUCCESS',
	GET_AGENCY_ROLES_FAILURE: 'GET_AGENCY_ROLES_FAILURE',

	GET_ALL_PERMISSION_REQUEST: 'GET_ALL_PERMISSION_REQUEST',
	GET_ALL_PERMISSION_SUCCESS: 'GET_ALL_PERMISSION_SUCCESS',
	GET_ALL_PERMISSION_FAILURE: 'GET_ALL_PERMISSION_FAILURE',

	PAGINATION_ROLES_REQUEST: 'PAGINATION_ROLES_REQUEST',
	PAGINATION_ROLES_SUCCESS: 'PAGINATION_ROLES_SUCCESS',
	PAGINATION_ROLES_FAILURE: 'PAGINATION_ROLES_FAILURE',

	STORE_ROLE_REQUEST: 'STORE_ROLE_REQUEST',
	STORE_ROLE_SUCCESS: 'STORE_ROLE_SUCCESS',
	STORE_ROLE_FAILURE: 'STORE_ROLE_FAILURE',

	GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
	GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
	GET_ROLE_FAILURE: 'GET_ROLE_FAILURE',

	EDIT_ROLE_REQUEST: 'EDIT_ROLE_REQUEST',
	EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
	EDIT_ROLE_FAILURE: 'EDIT_ROLE_FAILURE',

	DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
	DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
	DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE',

	PAGINATION_PERMISSIONS_REQUEST: 'PAGINATION_PERMISSIONS_REQUEST',
	PAGINATION_PERMISSIONS_SUCCESS: 'PAGINATION_PERMISSIONS_SUCCESS',
	PAGINATION_PERMISSIONS_FAILURE: 'PAGINATION_PERMISSIONS_FAILURE',

	STORE_PERMISSION_REQUEST: 'STORE_PERMISSION_REQUEST',
	STORE_PERMISSION_SUCCESS: 'STORE_PERMISSION_SUCCESS',
	STORE_PERMISSION_FAILURE: 'STORE_PERMISSION_FAILURE',

	GET_PERMISSION_REQUEST: 'GET_PERMISSION_REQUEST',
	GET_PERMISSION_SUCCESS: 'GET_PERMISSION_SUCCESS',
	GET_PERMISSION_FAILURE: 'GET_PERMISSION_FAILURE',

	EDIT_PERMISSION_REQUEST: 'EDIT_PERMISSION_REQUEST',
	EDIT_PERMISSION_SUCCESS: 'EDIT_PERMISSION_SUCCESS',
	EDIT_PERMISSION_FAILURE: 'EDIT_PERMISSION_FAILURE',

	DELETE_PERMISSION_REQUEST: 'DELETE_PERMISSION_REQUEST',
	DELETE_PERMISSION_SUCCESS: 'DELETE_PERMISSION_SUCCESS',
	DELETE_PERMISSION_FAILURE: 'DELETE_PERMISSION_FAILURE',

	GET_ALL_PARENT_LABEL_REQUEST: 'GET_ALL_PARENT_LABEL_REQUEST',
	GET_ALL_PARENT_LABEL_SUCCESS: 'GET_ALL_PARENT_LABEL_SUCCESS',
	GET_ALL_PARENT_LABEL_FAILURE: 'GET_ALL_PARENT_LABEL_FAILURE',

	GET_ALREADY_EXISTING_ORDER_REQUEST: 'GET_ALREADY_EXISTING_ORDER_REQUEST',
	GET_ALREADY_EXISTING_ORDER_SUCCESS: 'GET_ALREADY_EXISTING_ORDER_SUCCESS',
	GET_ALREADY_EXISTING_ORDER_FAILURE: 'GET_ALREADY_EXISTING_ORDER_FAILURE',




	GET_AGENCY_TYPE_REQUEST: 'GET_AGENCY_TYPE_REQUEST',
	GET_AGENCY_TYPE_SUCCESS: 'GET_AGENCY_TYPE_SUCCESS',
	GET_AGENCY_TYPE_FAILURE: 'GET_AGENCY_TYPE_FAILURE',

	GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
	GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
	GET_COUNTRIES_FAILURE: 'GET_COUNTRIES_FAILURE',

	GET_PRIMARY_STATES_REQUEST: 'GET_PRIMARY_STATES_REQUEST',
	GET_PRIMARY_STATES_SUCCESS: 'GET_PRIMARY_STATES_SUCCESS',
	GET_PRIMARY_STATES_FAILURE: 'GET_PRIMARY_STATES_FAILURE',

	GET_SECONDARY_STATES_REQUEST: 'GET_SECONDARY_STATES_REQUEST',
	GET_SECONDARY_STATES_SUCCESS: 'GET_SECONDARY_STATES_SUCCESS',
	GET_SECONDARY_STATES_FAILURE: 'GET_SECONDARY_STATES_FAILURE',


	GET_STATES_REQUEST: 'GET_STATES_REQUEST',
	GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
	GET_STATES_FAILURE: 'GET_STATES_FAILURE',


	GET_SALES_REP_REQUEST: 'GET_SALES_REP_REQUEST',
	GET_SALES_REP_SUCCESS: 'GET_SALES_REP_SUCCESS',
	GET_SALES_REP_FAILURE: 'GET_SALES_REP_FAILURE',

	GET_AGENCY_SALES_REP_REQUEST: 'GET_AGENCY_SALES_REP_REQUEST',
	GET_AGENCY_SALES_REP_SUCCESS: 'GET_AGENCY_SALES_REP_SUCCESS',
	GET_AGENCY_SALES_REP_FAILURE: 'GET_AGENCY_SALES_REP_FAILURE',


	GET_PROGRAM_TYPE_REQUEST: 'GET_PROGRAM_TYPE_REQUEST',
	GET_PROGRAM_TYPE_SUCCESS: 'GET_PROGRAM_TYPE_SUCCESS',
	GET_PROGRAM_TYPE_FAILURE: 'GET_PROGRAM_TYPE_FAILURE',

	GET_MAIN_PROGRAM_TYPE_REQUEST: 'GET_MAIN_PROGRAM_TYPE_REQUEST',
	GET_MAIN_PROGRAM_TYPE_SUCCESS: 'GET_MAIN_PROGRAM_TYPE_SUCCESS',
	GET_MAIN_PROGRAM_TYPE_FAILURE: 'GET_MAIN_PROGRAM_TYPE_FAILURE',

	GET_PROGRAM_ITEM_REQUEST: 'GET_PROGRAM_ITEM_REQUEST',
	GET_PROGRAM_ITEM_SUCCESS: 'GET_PROGRAM_ITEM_SUCCESS',
	GET_PROGRAM_ITEM_FAILURE: 'GET_PROGRAM_ITEM_FAILURE',

	GET_INVOICING_REQUEST: 'GET_INVOICING_REQUEST',
	GET_INVOICING_SUCCESS: 'GET_INVOICING_SUCCESS',
	GET_INVOICING_FAILURE: 'GET_INVOICING_FAILURE',

	CHANGE_AGENCY_STATUS_REQUEST: 'CHANGE_AGENCY_STATUS_REQUEST',
	CHANGE_AGENC_STATUS_SUCCESS: 'CHANGE_AGENC_STATUS_SUCCESS',
	CHANGE_AGENC_STATUS_FAILURE: 'CHANGE_AGENC_STATUS_FAILURE',

	PAGINATION_AGENCY_USERS_REQUEST: 'PAGINATION_AGENCY_USERS_REQUEST',
	PAGINATION_AGENCY_USERS_SUCCESS: 'PAGINATION_AGENCY_USERS_SUCCESS',
	PAGINATION_AGENCY_USERS_FAILURE: 'PAGINATION_AGENCY_USERS_FAILURE',

	GELL_ALL_AGENCY_USER_REQUEST: 'GELL_ALL_AGENCY_USER_REQUEST',
	GELL_ALL_AGENCY_USER_SUCCESS: 'GELL_ALL_AGENCY_USER_SUCCESS',
	GELL_ALL_AGENCY_USER_FAILURE: 'GELL_ALL_AGENCY_USER_FAILURE',


	GET_ALL_AGENCY_PUBLISH_ABLE_USER_REQUEST: 'GET_ALL_AGENCY_PUBLISH_ABLE_USER_REQUEST',
	GET_ALL_AGENCY_PUBLISH_ABLE_USER_SUCCESS: 'GET_ALL_AGENCY_PUBLISH_ABLE_USER_SUCCESS',
	GET_ALL_AGENCY_PUBLISH_ABLE_USER_FAILURE: 'GET_ALL_AGENCY_PUBLISH_ABLE_USER_FAILURE',



	STORE_AGENCY_USER_REQUEST: 'STORE_AGENCY_USER_REQUEST',
	STORE_AGENCY_USER_SUCCESS: 'STORE_AGENCY_USER_SUCCESS',
	STORE_AGENCY_USER_FAILURE: 'STORE_AGENCY_USER_FAILURE',

	GET_AGENCY_USER_REQUEST: 'GET_AGENCY_USER_REQUEST',
	GET_AGENCY_USER_SUCCESS: 'GET_AGENCY_USER_SUCCESS',
	GET_AGENCY_USER_FAILURE: 'GET_AGENCY_USER_FAILURE',

	EDIT_AGENCY_USER_REQUEST: 'EDIT_AGENCY_USER_REQUEST',
	EDIT_AGENCY_USER_SUCCESS: 'EDIT_AGENCY_USER_SUCCESS',
	EDIT_AGENCY_USER_FAILURE: 'EDIT_AGENCY_USER_FAILURE',

	DELETE_AGENCY_USER_REQUEST: 'DELETE_AGENCY_USER_REQUEST',
	DELETE_AGENCY_USER_SUCCESS: 'DELETE_AGENCY_USER_SUCCESS',
	DELETE_AGENCY_USER_FAILURE: 'DELETE_AGENCY_USER_FAILURE',

	PAGINATION_AGENCY_RESOURCES_REQUEST: 'PAGINATION_AGENCY_RESOURCES_REQUEST',
	PAGINATION_AGENCY_RESOURCES_SUCCESS: 'PAGINATION_AGENCY_RESOURCES_SUCCESS',
	PAGINATION_AGENCY_RESOURCES_FAILURE: 'PAGINATION_AGENCY_RESOURCES_FAILURE',

	STORE_AGENCY_RESOURCE_REQUEST: 'STORE_AGENCY_RESOURCE_REQUEST',
	STORE_AGENCY_RESOURCE_SUCCESS: 'STORE_AGENCY_RESOURCE_SUCCESS',
	STORE_AGENCY_RESOURCE_FAILURE: 'STORE_AGENCY_RESOURCE_FAILURE',

	GET_AGENCY_RESOURCE_REQUEST: 'GET_AGENCY_RESOURCE_REQUEST',
	GET_AGENCY_RESOURCE_SUCCESS: 'GET_AGENCY_RESOURCE_SUCCESS',
	GET_AGENCY_RESOURCE_FAILURE: 'GET_AGENCY_RESOURCE_FAILURE',

	GET_AGENCY_RESOURCE_TYPE_REQUEST: 'GET_AGENCY_RESOURCE_TYPE_REQUEST',
	GET_AGENCY_RESOURCE_TYPE_SUCCESS: 'GET_AGENCY_RESOURCE_TYPE_SUCCESS',
	GET_AGENCY_RESOURCE_TYPE_FAILURE: 'GET_AGENCY_RESOURCE_TYPE_FAILURE',

	EDIT_AGENCY_RESOURCE_REQUEST: 'EDIT_AGENCY_RESOURCE_REQUEST',
	EDIT_AGENCY_RESOURCE_SUCCESS: 'EDIT_AGENCY_RESOURCE_SUCCESS',
	EDIT_AGENCY_RESOURCE_FAILURE: 'EDIT_AGENCY_RESOURCE_FAILURE',

	DELETE_AGENCY_RESOURCE_REQUEST: 'DELETE_AGENCY_RESOURCE_REQUEST',
	DELETE_AGENCY_RESOURCE_SUCCESS: 'DELETE_AGENCY_RESOURCE_SUCCESS',
	DELETE_AGENCY_RESOURCE_FAILURE: 'DELETE_AGENCY_RESOURCE_FAILURE',

	GET_PROGRAM_ITEMS_REQUEST: 'GET_PROGRAM_ITEMS_REQUEST',
	GET_PROGRAM_ITEMS_SUCCESS: 'GET_PROGRAM_ITEMS_SUCCESS',
	GET_PROGRAM_ITEMS_FAILURE: 'GET_PROGRAM_ITEMS_FAILURE',

	GET_SETTING_VALUE_REQUEST: 'GET_SETTING_VALUE_REQUEST',
	GET_SETTING_VALUE_SUCCESS: 'GET_SETTING_VALUE_SUCCESS',
	GET_SETTING_VALUE_FAILURE: 'GET_SETTING_VALUE_FAILURE',

	GET_SETTING_VALUE_GM_REQUEST: 'GET_SETTING_VALUE_GM_REQUEST',
	GET_SETTING_VALUE_GM_SUCCESS: 'GET_SETTING_VALUE_GM_SUCCESS',
	GET_SETTING_VALUE_GM_FAILURE: 'GET_SETTING_VALUE_GM_FAILURE',

	PAGINATION_ORDERS_REQUEST: 'PAGINATION_ORDERS_REQUEST',
	PAGINATION_ORDERS_SUCCESS: 'PAGINATION_ORDERS_SUCCESS',
	PAGINATION_ORDERS_FAILURE: 'PAGINATION_ORDERS_FAILURE',

	STORE_ORDER_REQUEST: 'STORE_ORDER_REQUEST',
	STORE_ORDER_SUCCESS: 'STORE_ORDER_SUCCESS',
	STORE_ORDER_FAILURE: 'STORE_ORDER_FAILURE',

	ORDER_RESOURCE_FORMATS_REQUEST: 'ORDER_RESOURCE_FORMATS_REQUEST',
	ORDER_RESOURCE_FORMATS_SUCCESS: 'ORDER_RESOURCE_FORMATS_SUCCESS',
	ORDER_RESOURCE_FORMATS_FAILURE: 'ORDER_RESOURCE_FORMATS_FAILURE',

	GET_ALL_MODULES: 'GET_ALL_MODULES',
	STORE_MODULE_REQUEST: 'STORE_MODULE_REQUEST',
	STORE_MODULE_SUCCESS: 'STORE_MODULE_SUCCESS',
	STORE_MODULE_FAILURE: 'STORE_MODULE_FAILURE',
	CHANGE_MODULE_PERMISSIONS: 'CHANGE_MODULE_PERMISSIONS',

	GET_USER_PREFERENCE_REQUEST: 'GET_USER_PREFERENCE_REQUEST',
	GET_USER_PREFERENCE_SUCCESS: 'GET_USER_PREFERENCE_SUCCESS',
	GET_USER_PREFERENCE_FAILURE: 'GET_USER_PREFERENCE_FAILURE',

	STORE_PREFERENCE_REQUEST: 'STORE_PREFERENCE_REQUEST',
	STORE_PREFERENCE_SUCCESS: 'STORE_PREFERENCE_SUCCESS',
	STORE_PREFERENCE_FAILURE: 'STORE_PREFERENCE_FAILURE',

	CLEAR_SETTINGS_REQUEST: 'CLEAR_SETTINGS_REQUEST',

	GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
	GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
	GET_ORDER_FAILURE: 'GET_ORDER_FAILURE',

	ORDER_DETAIL_EDIT_REQUEST: 'ORDER_DETAIL_EDIT_REQUEST',
	SHIPPING_DETAIL_EDIT_REQUEST: 'SHIPPING_DETAIL_EDIT_REQUEST',

	LOADING_ORDER_FOLDER_RESOURCES_REQUEST:"LOADING_ORDER__FOLDER_RESOURCES_REQUEST",
	LOADING_ORDER_FOLDER_RESOURCES_FAILURE:"LOADING_ORDER__FOLDER_RESOURCES_FAILURE",
	LOADING_ORDER_FOLDER_RESOURCES_SUCCESS:"LOADING_ORDER__FOLDER_RESOURCES_SUCCESS",

	UUID_LOADING_ORDER_FOLDER_RESOURCES_REQUEST:"UUID_LOADING_ORDER__FOLDER_RESOURCES_REQUEST",
	UUID_LOADING_ORDER_FOLDER_RESOURCES_FAILURE:"UUID_LOADING_ORDER__FOLDER_RESOURCES_FAILURE",
	UUID_LOADING_ORDER_FOLDER_RESOURCES_SUCCESS:"UUID_LOADING_ORDER__FOLDER_RESOURCES_SUCCESS",

	LOADING_AGENCY_FOLDER_RESOURCES_REQUEST:"LOADING_AGENCY__FOLDER_RESOURCES_REQUEST",
	LOADING_AGENCY_FOLDER_RESOURCES_FAILURE:"LOADING_AGENCY__FOLDER_RESOURCES_FAILURE",
	LOADING_AGENCY_FOLDER_RESOURCES_SUCCESS:"LOADING_AGENCY__FOLDER_RESOURCES_SUCCESS",

	UPLOAD_FILE: 'UPLOAD_FILE',
	UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
	UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
	ORDER_UPLOAD_CANCEL: 'ORDER_UPLOAD_CANCEL',
	LOADING_ORDER_RESOURCES_REQUEST: 'LOADING_ORDER_RESOURCES_REQUEST',
	LOADING_ORDER_RESOURCES_SUCCESS: 'LOADING_ORDER_RESOURCES_SUCCESS',
	LOADING_ORDER_RESOURCES_FAILURE: 'LOADING_ORDER_RESOURCES_FAILURE',

	ORDER_RESOURCE_DELETE_REQUEST: 'ORDER_RESOURCE_DELETE_REQUEST',
	ORDER_RESOURCE_DELETE_SUCCESS: 'ORDER_RESOURCE_DELETE_SUCCESS',
	ORDER_RESOURCE_DELETE_FAILURE: 'ORDER_RESOURCE_DELETE_FAILURE',

	GET_TRIP_ORDER_USERS_REQUEST: 'GET_TRIP_ORDER_USERS_REQUEST',
	GET_TRIP_ORDER_USERS_SUCCESS: 'GET_TRIP_ORDER_USERS_SUCCESS',
	GET_TRIP_ORDER_USERS_FAILURE: 'GET_TRIP_ORDER_USERS_FAILURE',

	GET_FOLLOWING_ORDER_USERS_REQUEST: 'GET_FOLLOWING_ORDER_USERS_REQUEST',
	GET_FOLLOWING_ORDER_USERS_SUCCESS: 'GET_FOLLOWING_ORDER_USERS_SUCCESS',
	GET_FOLLOWING_ORDER_USERS_FAILURE: 'GET_FOLLOWING_ORDER_USERS_FAILURE',

	GET_SPECIAL_ORDER_USER_REQUEST: 'GET_SPECIAL_ORDER_USER_REQUEST',
	GET_SPECIAL_ORDER_USER_SUCCESS: 'GET_SPECIAL_ORDER_USER_SUCCESS',
	GET_SPECIAL_ORDER_USER_FAILURE: 'GET_SPECIAL_ORDER_USER_FAILURE',

	CREATE_SPECIAL_ORDER_USER_REQUEST: 'CREATE_SPECIAL_ORDER_USER_REQUEST',
	CREATE_SPECIAL_ORDER_USER_SUCCESS: 'CREATE_SPECIAL_ORDER_USER_SUCCESS',
	CREATE_SPECIAL_ORDER_USER_FAILURE: 'CREATE_SPECIAL_ORDER_USER_FAILURE',

	UPDATE_SPECIAL_ORDER_USER_SUCCESS: 'UPDATE_SPECIAL_ORDER_USER_SUCCESS',
	UPDATE_SPECIAL_ORDER_USER_FAILURE: 'UPDATE_SPECIAL_ORDER_USER_FAILURE',

	DELETE_ORDER_USER_REQUEST: 'DELETE_ORDER_USER_REQUEST',
	DELETE_ORDER_USER_SUCCESS: 'DELETE_ORDER_USER_SUCCESS',
	DELETE_ORDER_USER_FAILURE: 'DELETE_ORDER_USER_FAILURE',

	ORDER_USER_UPDATE_REQUEST: 'ORDER_USER_UPDATE_REQUEST',
	ORDER_USER_UPDATE_SUCCESS: 'ORDER_USER_UPDATE_SUCCESS',
	ORDER_USER_UPDATE_FAILURE: 'ORDER_USER_UPDATE_FAILURE',

	APPROVE_ORDER_UPLOAD_REQUEST: 'APPROVE_ORDER_UPLOAD_REQUEST',
	APPROVE_ORDER_UPLOAD_SUCCESS: 'APPROVE_ORDER_UPLOAD_SUCCESS',
	APPROVE_ORDER_UPLOAD_FAILURE: 'APPROVE_ORDER_UPLOAD_FAILURE',

	GET_ORDER_SETTINGS_REQUEST: 'GET_ORDER_SETTINGS_REQUEST',
	GET_ORDER_SETTINGS_SUCCESS: 'GET_ORDER_SETTINGS_SUCCESS',
	GET_ORDER_SETTINGS_FAILURE: 'GET_ORDER_SETTINGS_FAILURE',

    GET_ALLOW_REORDER_SUCCESS: 'GET_ALLOW_REORDER_SUCCESS',
	GET_ALLOW_REORDER_FAILURE: 'GET_ALLOW_REORDER_FAILURE',

    ALLOW_REORDER_STATUS_SUCCESS: 'ALLOW_REORDER_STATUS_SUCCESS',
	ALLOW_REORDER_STATUS_FAILURE: 'ALLOW_REORDER_STATUS_FAILURE',

    GET_ORDER_IMAGE_PATH_SUCCESS: 'GET_ORDER_IMAGE_PATH_SUCCESS',
	GET_ORDER_IMAGE_PATH_FAILURE: 'GET_ORDER_IMAGE_PATH_FAILURE',

	ORDER_HASH_TAG_CHANGE_SUCCESS: 'ORDER_HASH_TAG_CHANGE_SUCCESS',
	ORDER_HASH_TAG_CHANGE_FAILURE: 'ORDER_HASH_TAG_CHANGE_FAILURE',

	ORDER_MESSAGING_CHANGE_SUCCESS: 'ORDER_MESSAGING_CHANGE_SUCCESS',

	ORDER_OPEN_PHOTO_UPLOAD_SUCCESS: 'ORDER_OPEN_PHOTO_UPLOAD_SUCCESS',
	ORDER_OPEN_PHOTO_UPLOAD_FAILURE: 'ORDER_OPEN_PHOTO_UPLOAD_FAILURE',

	ORDER_MAX_PHOTO_UPLOAD_SUCCESS: 'ORDER_MAX_PHOTO_UPLOAD_SUCCESS',
	ORDER_MAX_PHOTO_UPLOAD_FAILURE: 'ORDER_MAX_PHOTO_UPLOAD_FAILURE',

	ORDER_AUTO_APPROVE_PHOTOS_SUCCESS: 'ORDER_AUTO_APPROVE_PHOTOS_SUCCESS',
	ORDER_AUTO_APPROVE_PHOTOS_FAILURE: 'ORDER_AUTO_APPROVE_PHOTOS_FAILURE',

	ORDER_APPROVE_VIDEO_UPLOAD_SUCCESS: 'ORDER_APPROVE_VIDEO_UPLOAD_SUCCESS',
	ORDER_APPROVE_VIDEO_UPLOAD_FAILURE: 'ORDER_APPROVE_VIDEO_UPLOAD_FAILURE',

	ORDER_UPLOAD_CUT_OFF_DATE_SUCCESS: 'ORDER_UPLOAD_CUT_OFF_DATE_SUCCESS',
	ORDER_UPLOAD_CUT_OFF_DATE_FAILURE: 'ORDER_UPLOAD_CUT_OFF_DATE_FAILURE',

	ORDER_UPLOAD_CUT_OFF_DATE_BY_EMAIL_SUCCESS: 'ORDER_UPLOAD_CUT_OFF_DATE_BY_EMAIL_SUCCESS',
	ORDER_UPLOAD_CUT_OFF_DATE_BY_EMAIL_FAILURE: 'ORDER_UPLOAD_CUT_OFF_DATE_BY_EMAIL_FAILURE',

	GET_USERS_ORDER_REQUEST: 'GET_USERS_ORDER_REQUEST',
	GET_USERS_ORDER_SUCCESS: 'GET_USERS_ORDER_SUCCESS',
	GET_USERS_ORDER_FAILURE: 'GET_USERS_ORDER_FAILURE',

	UPDATE_USERS_MODERATOR_REQUEST: 'UPDATE_USERS_MODERATOR_REQUEST',
	UPDATE_USERS_MODERATOR_SUCCESS: 'UPDATE_USERS_MODERATOR_SUCCESS',
	UPDATE_USERS_MODERATOR_FAILURE: 'UPDATE_USERS_MODERATOR_FAILURE',

	UPDATE_ORDER_VIDEO_TITLES_SUCCESS: 'UPDATE_ORDER_VIDEO_TITLES_SUCCESS',
	UPDATE_ORDER_VIDEO_TITLES_FAILURE: 'UPDATE_ORDER_VIDEO_TITLES_FAILURE',

	UPDATE_ORDER_EQUIPMENT_UPLOAD_SUCCESS: 'UPDATE_ORDER_EQUIPMENT_UPLOAD_SUCCESS',
	UPDATE_ORDER_EQUIPMENT_UPLOAD_FAILURE: 'UPDATE_ORDER_EQUIPMENT_UPLOAD_FAILURE',

	UPDATE_ORDER_CAMERA_SHIP_STATUS_SUCCESS: 'UPDATE_ORDER_CAMERA_SHIP_STATUS_SUCCESS',

	UPDATE_ORDER_VIDEO_SHIP_STATUS_SUCCESS: 'UPDATE_ORDER_VIDEO_SHIP_STATUS_SUCCESS',

	UPDATE_ORDER_MOSAIC_SHIP_STATUS_SUCCESS: 'UPDATE_ORDER_MOSAIC_SHIP_STATUS_SUCCESS',

	UPDATE_ORDER_CAMERA_SHIP_STATUS_FAILURE: 'UPDATE_ORDER_CAMERA_SHIP_STATUS_FAILURE',

	UPDATE_ORDER_RETURN_SHIP_STATUS_SUCCESS: 'UPDATE_ORDER_RETURN_SHIP_STATUS_SUCCESS',

	GET_ALL_SHIPPING_METHODS_SUCCESS: 'GET_ALL_SHIPPING_METHODS_SUCCESS',
	GET_ALL_SHIPPING_METHODS_FAILURE: 'GET_ALL_SHIPPING_METHODS_FAILURE',

	GET_COMMENT_CATEGORIES_SUCCESS: 'GET_COMMENT_CATEGORIES_SUCCESS',
	GET_COMMENT_CATEGORIES_FAILURE: 'GET_COMMENT_CATEGORIES_FAILURE',

	GET_ALL_COMMENTS_SUCCESS: 'GET_ALL_COMMENTS_SUCCESS',
	GET_ALL_COMMENTS_FAILURE: 'GET_ALL_COMMENTS_FAILURE',

	DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
	DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',

	ADD_COMMENT : 'ADD_COMMENT',
	ADD_COMMENT_REQUEST : 'ADD_COMMENT_REQUEST',
	ADD_COMMENT_SUCCESS: 'ADD_COMMENT_SUCCESS',
	ADD_COMMENT_FAILURE: 'ADD_COMMENT_FAILURE',

	EDIT_COMMENT_SUCCESS: 'EDIT_COMMENT_SUCCESS',
	EDIT_COMMENT_FAILURE: 'EDIT_COMMENT_FAILURE',

	UPDATE_ORDER_DETAIL_SUCCESS: 'UPDATE_ORDER_DETAIL_SUCCESS',
	UPDATE_ORDER_DETAIL_FAILURE: 'UPDATE_ORDER_DETAIL_FAILURE',

	UPDATE_PRICING_DETAIL_SUCCESS: 'UPDATE_PRICING_DETAIL_SUCCESS',
	UPDATE_PRICING_DETAIL_FAILURE: 'UPDATE_PRICING_DETAIL_FAILURE',

	UPDATE_SHIPPING_DETAIL_SUCCESS: 'UPDATE_SHIPPING_DETAIL_SUCCESS',
	UPDATE_SHIPPING_DETAIL_FAILURE: 'UPDATE_SHIPPING_DETAIL_FAILURE',

	UPDATE_PROJECTED_PTM_DATE_SUCCESS: 'UPDATE_PROJECTED_PTM_DATE_SUCCESS',
	UPDATE_PROJECTED_PTM_DATE_FAILURE: 'UPDATE_PROJECTED_PTM_DATE_FAILURE',

	GET_ALL_CAMERAS_TO_SHIP_SUCCESS: 'GET_ALL_CAMERAS_TO_SHIP_SUCCESS',
	GET_ALL_CAMERAS_TO_SHIP_FAILURE: 'GET_ALL_CAMERAS_TO_SHIP_FAILURE',

	GET_ORDER_IMAGES_REQUEST: 'GET_ORDER_IMAGES_REQUEST',
	GET_ORDER_IMAGES_SUCCESS: 'GET_ORDER_IMAGES_SUCCESS',
	GET_ORDER_IMAGES_FAILURE: 'GET_ORDER_IMAGES_FAILURE',

	TOGGLE_COMMENT_HIGHLIGHT_SUCCESS: 'TOGGLE_COMMENT_HIGHLIGHT_SUCCESS',
	TOGGLE_COMMENT_HIGHLIGHT_FAILURE: 'TOGGLE_COMMENT_HIGHLIGHT_FAILURE',

	GET_ALL_ORDER_PHASE_IMAGES_REQUEST: 'GET_ALL_ORDER_PHASE_IMAGES_REQUEST',
	GET_ALL_ORDER_PHASE_IMAGES_SUCCESS: 'GET_ALL_ORDER_PHASE_IMAGES_SUCCESS',
	GET_ALL_ORDER_PHASE_IMAGES_FAILURE: 'GET_ALL_ORDER_PHASE_IMAGES_FAILURE',

	GET_ALL_HOLIDAYS_SUCCESS: 'GET_ALL_HOLIDAYS_SUCCESS',
	GET_ALL_HOLIDAYS_FAILURE: 'GET_ALL_HOLIDAYS_FAILURE',

	GET_ALL_REQUIRED_ASSESTS_SUCCESS: 'GET_ALL_REQUIRED_ASSESTS_SUCCESS',
	GET_ALL_REQUIRED_ASSESTS_FAILURE: 'GET_ALL_REQUIRED_ASSESTS_FAILURE',

	GET_ALL_RECENTLY_PROCESSED_ORDERS_SUCCESS: 'GET_ALL_RECENTLY_PROCESSED_ORDERS_SUCCESS',
	GET_ALL_RECENTLY_PROCESSED_ORDERS_FAILURE: 'GET_ALL_RECENTLY_PROCESSED_ORDERS_FAILURE',

	GET_ALL_BRANDS_SUCCESS: 'GET_ALL_BRANDS_SUCCESS',
	GET_ALL_BRANDS_FAILURE: 'GET_ALL_BRANDS_FAILURE',

	GET_ALL_ITEM_CATEGORIES_SUCCESS: 'GET_ALL_ITEM_CATEGORIES_SUCCESS',
	GET_ALL_ITEM_CATEGORIES_FAILURE: 'GET_ALL_ITEM_CATEGORIES_FAILURE',

	STORE_PROGRAM_TYPE_SUCCESS: 'STORE_PROGRAM_TYPE_SUCCESS',
	STORE_PROGRAM_TYPE_FAILURE: 'STORE_PROGRAM_TYPE_FAILURE',

	UPDATE_PROGRAM_TYPE_SUCCESS: 'UPDATE_PROGRAM_TYPE_SUCCESS',
	UPDATE_PROGRAM_TYPE_FAILURE: 'UPDATE_PROGRAM_TYPE_FAILURE',

	DELETE_PROGRAM_TYPE_SUCCESS: 'DELETE_PROGRAM_TYPE_SUCCESS',
	DELETE_PROGRAM_TYPE_FAILURE: 'DELETE_PROGRAM_TYPE_FAILURE',

	GET_SINGLE_PROGRAM_TYPE_SUCCESS: 'GET_SINGLE_PROGRAM_TYPE_SUCCESS',
	GET_SINGLE_PROGRAM_TYPE_FAILURE: 'GET_SINGLE_PROGRAM_TYPE_FAILURE',

	GET_PROGRAM_TYPES_PAGINATION_SUCCESS: 'GET_PROGRAM_TYPES_PAGINATION_SUCCESS',
	GET_PROGRAM_TYPES_PAGINATION_FAILURE: 'GET_PROGRAM_TYPES_PAGINATION_FAILURE',

	GET_AGENCY_TYPES_PAGINATION_SUCCESS: 'GET_AGENCY_TYPES_PAGINATION_SUCCESS',
	GET_AGENCY_TYPES_PAGINATION_FAILURE: 'GET_AGENCY_TYPES_PAGINATION_FAILURE',

	GET_AGENCY_TYPES_SINGLE_SUCCESS: 'GET_AGENCY_TYPES_SINGLE_SUCCESS',
	GET_AGENCY_TYPES_SINGLE_FAILURE: 'GET_AGENCY_TYPES_SINGLE_FAILURE',

	STORE_AGENCY_TYPES_SINGLE_SUCCESS: 'STORE_AGENCY_TYPES_SINGLE_SUCCESS',
	STORE_AGENCY_TYPES_SINGLE_FAILURE: 'STORE_AGENCY_TYPES_SINGLE_FAILURE',

	UPDATE_AGENCY_TYPES_SINGLE_SUCCESS: 'UPDATE_AGENCY_TYPES_SINGLE_SUCCESS',
	UPDATE_AGENCY_TYPES_SINGLE_FAILURE: 'UPDATE_AGENCY_TYPES_SINGLE_FAILURE',

	DELETE_AGENCY_TYPES_SINGLE_SUCCESS: 'DELETE_AGENCY_TYPES_SINGLE_SUCCESS',
	DELETE_AGENCY_TYPES_SINGLE_FAILURE: 'DELETE_AGENCY_TYPES_SINGLE_FAILURE',

	STORE_EDITOR_ORDER_IMG_REQUEST: 'STORE_EDITOR_ORDER_IMG_REQUEST',
	STORE_EDITOR_ORDER_IMG_SUCCESS: 'STORE_EDITOR_ORDER_IMG_SUCCESS',
	STORE_EDITOR_ORDER_IMG_FAILURE: 'STORE_EDITOR_ORDER_IMG_FAILURE',

	GET_SETTING_BY_MODEL_ID_REQUEST: 'GET_SETTING_BY_MODEL_ID_REQUEST',
	GET_SETTING_BY_MODEL_ID_SUCCESS: 'GET_SETTING_BY_MODEL_ID_SUCCESS',
	GET_SETTING_BY_MODEL_ID_FAILURE: 'GET_SETTING_BY_MODEL_ID_FAILURE',

	STORE_SETTINGS_DATA_REQUEST: 'STORE_SETTINGS_DATA_REQUEST',
	STORE_SETTINGS_DATA_SUCCESS: 'STORE_SETTINGS_DATA_SUCCESS',
	STORE_SETTINGS_DATA_FAILURE: 'STORE_SETTINGS_DATA_FAILURE',

	UPDATE_SETTINGS_DATA_REQUEST: 'UPDATE_SETTINGS_DATA_REQUEST',
	UPDATE_SETTINGS_DATA_SUCCESS: 'UPDATE_SETTINGS_DATA_SUCCESS',
	UPDATE_SETTINGS_DATA_FAILURE: 'UPDATE_SETTINGS_DATA_FAILURE',

	ASSIGN_USER_TO_ORDER_REQUEST: 'ASSIGN_USER_TO_ORDER_REQUEST',
	ASSIGN_USER_TO_ORDER_SUCCESS: 'ASSIGN_USER_TO_ORDER_SUCCESS',
	ASSIGN_USER_TO_ORDER_FAILURE: 'ASSIGN_USER_TO_ORDER_FAILURE',

	GET_ADDITIONAL_SERVICES_SUCCESS: 'GET_ADDITIONAL_SERVICES_SUCCESS',
	GET_ADDITIONAL_SERVICES_FAILURE: 'GET_ADDITIONAL_SERVICES_FAILURE',

	GET_SINGLE_ADDITIONAL_ITEM_SUCCESS: 'GET_SINGLE_ADDITIONAL_ITEM_SUCCESS',
	GET_SINGLE_ADDITIONAL_ITEM_FAILURE: 'GET_SINGLE_ADDITIONAL_ITEM_FAILURE',

	UPDATE_SINGLE_ADDITIONAL_ITEM_SUCCESS: 'UPDATE_SINGLE_ADDITIONAL_ITEM_SUCCESS',
	UPDATE_SINGLE_ADDITIONAL_ITEM_FAILURE: 'UPDATE_SINGLE_ADDITIONAL_ITEM_FAILURE',

	DELETE_SINGLE_ADDITIONAL_ITEM_SUCCESS: 'DELETE_SINGLE_ADDITIONAL_ITEM_SUCCESS',
	DELETE_SINGLE_ADDITIONAL_ITEM_FAILURE: 'DELETE_SINGLE_ADDITIONAL_ITEM_FAILURE',

	UPDATE_ORDER_SETTINGS_SUCCESS: 'UPDATE_ORDER_SETTINGS_SUCCESS',
	UPDATE_ORDER_SETTINGS_FAILURE: 'UPDATE_ORDER_SETTINGS_FAILURE',

	GET_INVENTORY_LIST_REQUEST: 'GET_INVENTORY_LIST_REQUEST',
	GET_INVENTORY_LIST_SUCCESS: 'GET_INVENTORY_LIST_SUCCESS',
	GET_INVENTORY_LIST_FAILURE: 'GET_INVENTORY_LIST_FAILURE',

	GET_ITEMS_REQUEST: 'GET_ITEMS_REQUEST',
	GET_ITEMS_SUCCESS: 'GET_ITEMS_SUCCESS',
	GET_ITEMS_FAILURE: 'GET_ITEMS_FAILURE',

	STORE_INVENTORY_REQUEST: 'STORE_INVENTORY_REQUEST',
	STORE_INVENTORY_SUCCESS: 'STORE_INVENTORY_SUCCESS',
	STORE_INVENTORY_FAILURE: 'STORE_INVENTORY_FAILURE',

	GET_INVENTORY_REQUEST: 'GET_INVENTORY_REQUEST',
	GET_INVENTORY_SUCCESS: 'GET_INVENTORY_SUCCESS',
	GET_INVENTORY_FAILURE: 'GET_INVENTORY_FAILURE',

	UPDATE_INVENTORY_REQUEST: 'UPDATE_INVENTORY_REQUEST',
	UPDATE_INVENTORY_SUCCESS: 'UPDATE_INVENTORY_SUCCESS',
	UPDATE_INVENTORY_FAILURE: 'UPDATE_INVENTORY_FAILURE',

	DELETE_INVENTORY_REQUEST: 'DELETE_INVENTORY_REQUEST',
	DELETE_INVENTORY_SUCCESS: 'DELETE_INVENTORY_SUCCESS',
	DELETE_INVENTORY_FAILURE: 'DELETE_INVENTORY_FAILURE',

	STORE_PM_ORDER_VIDEO_SUCCESS: 'STORE_PM_ORDER_VIDEO_SUCCESS',
	STORE_PM_ORDER_VIDEO_FAILURE: 'STORE_PM_ORDER_VIDEO_FAILURE',

	GET_ORDERS_RESOURCES_BY_ROLE_SUCCESS: 'GET_ORDERS_RESOURCES_BY_ROLE_SUCCESS',
	GET_ORDERS_RESOURCES_BY_ROLE_FAILURE: 'GET_ORDERS_RESOURCES_BY_ROLE_FAILURE',

	SET_GLOBAL_LOADER: 'SET_GLOBAL_LOADER',

	GET_CONTACT_LOG_TYPES_REQUEST: 'GET_CONTACT_LOG_TYPES_REQUEST',
	GET_CONTACT_LOG_TYPES_SUCCESS: 'GET_CONTACT_LOG_TYPES_SUCCESS',
	GET_CONTACT_LOG_TYPES_FAILURE: 'GET_CONTACT_LOG_TYPES_FAILURE',

	STORE_ORDER_ASSIGNED_ITEMS_REQUEST: 'STORE_ORDER_ASSIGNED_ITEMS_REQUEST',
	STORE_ORDER_ASSIGNED_ITEMS_SUCCESS: 'STORE_ORDER_ASSIGNED_ITEMS_SUCCESS',
	STORE_ORDER_ASSIGNED_ITEMS_FAILURE: 'STORE_ORDER_ASSIGNED_ITEMS_FAILURE',

	GET_ITEM_LIST_REQUEST: 'GET_ITEM_LIST_REQUEST',
	GET_ITEM_LIST_SUCCESS: 'GET_ITEM_LIST_SUCCESS',
	GET_ITEM_LIST_FAILURE: 'GET_ITEM_LIST_FAILURE',

	STORE_ITEM_REQUEST: 'STORE_ITEM_REQUEST',
	STORE_ITEM_SUCCESS: 'STORE_ITEM_SUCCESS',
	STORE_ITEM_FAILURE: 'STORE_ITEM_FAILURE',

	GET_ITEM_REQUEST: 'GET_ITEM_REQUEST',
	GET_ITEM_SUCCESS: 'GET_ITEM_SUCCESS',
	GET_ITEM_FAILURE: 'GET_ITEM_FAILURE',

	UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
	UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
	UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',

	DELETE_ITEM_REQUEST: 'DELETE_ITEM_REQUEST',
	DELETE_ITEM_SUCCESS: 'DELETE_ITEM_SUCCESS',
	DELETE_ITEM_FAILURE: 'DELETE_ITEM_FAILURE',

	GET_ITEM_CATEGORY_REQUEST: 'GET_ITEM_CATEGORY_REQUEST',
	GET_ITEM_CATEGORY_SUCCESS: 'GET_ITEM_CATEGORY_SUCCESS',
	GET_ITEM_CATEGORY_FAILURE: 'GET_ITEM_CATEGORY_FAILURE',

	GET_ITEM_TYPE_REQUEST: 'GET_ITEM_TYPE_REQUEST',
	GET_ITEM_TYPE_SUCCESS: 'GET_ITEM_TYPE_SUCCESS',
	GET_ITEM_TYPE_FAILURE: 'GET_ITEM_TYPE_FAILURE',

	GET_LPR_DATA_REQUEST: 'GET_LPR_DATA_REQUEST',
	GET_LPR_DATA_SUCCESS: 'GET_LPR_DATA_SUCCESS',
	GET_LPR_DATA_FAILURE: 'GET_LPR_DATA_FAILURE',

	GELL_INVENTORY_LOCATION_STATS_REQUEST: 'GELL_INVENTORY_LOCATION_STATS_REQUEST',
	GELL_INVENTORY_LOCATION_STATS_SUCCESS: 'GELL_INVENTORY_LOCATION_STATS_SUCCESS',
	GELL_INVENTORY_LOCATION_STATS_FAILURE: 'GELL_INVENTORY_LOCATION_STATS_FAILURE',

	GET_INV_LOCATIONS_REQUEST: 'GET_INV_LOCATIONS_REQUEST',
	GET_INV_LOCATIONS_SUCCESS: 'GET_INV_LOCATIONS_SUCCESS',
	GET_INV_LOCATIONS_FAILURE: 'GET_INV_LOCATIONS_FAILURE',

	UPDATE_INVENTORY_LOCATION_REQUEST: 'UPDATE_INVENTORY_LOCATION_REQUEST',
	UPDATE_INVENTORY_LOCATION_SUCCESS: 'UPDATE_INVENTORY_LOCATION_SUCCESS',
	UPDATE_INVENTORY_LOCATION_FAILURE: 'UPDATE_INVENTORY_LOCATION_FAILURE',


	GET_ORDER_RECEIVE_STATUS_SUCCESS: 'GET_ORDER_RECEIVE_STATUS_SUCCESS',
	GET_ORDER_RECEIVE_STATUS_FAILURE: 'GET_ORDER_RECEIVE_STATUS_FAILURE',

	UPDATE_USER_STATUS_REQUEST: 'UPDATE_USER_STATUS_REQUEST',
	UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
	UPDATE_USER_STATUS_FAILURE: 'UPDATE_USER_STATUS_FAILURE',

	PAGINATION_DESTINATIONS_REQUEST: 'PAGINATION_DESTINATIONS_REQUEST',
	PAGINATION_DESTINATIONS_SUCCESS: 'PAGINATION_DESTINATIONS_SUCCESS',
	PAGINATION_DESTINATIONS_FAILURE: 'PAGINATION_DESTINATIONS_FAILURE',

	STORE_DESTINATION_REQUEST: 'STORE_DESTINATION_REQUEST',
	STORE_DESTINATION_SUCCESS: 'STORE_DESTINATION_SUCCESS',
	STORE_DESTINATION_FAILURE: 'STORE_DESTINATION_FAILURE',

	GET_DESTINATION_REQUEST: 'GET_DESTINATION_REQUEST',
	GET_DESTINATION_SUCCESS: 'GET_DESTINATION_SUCCESS',
	GET_DESTINATION_FAILURE: 'GET_DESTINATION_FAILURE',

	EDIT_DESTINATION_REQUEST: 'EDIT_DESTINATION_REQUEST',
	EDIT_DESTINATION_SUCCESS: 'EDIT_DESTINATION_SUCCESS',
	EDIT_DESTINATION_FAILURE: 'EDIT_DESTINATION_FAILURE',

	DELETE_DESTINATION_REQUEST: 'DELETE_DESTINATION_REQUEST',
	DELETE_DESTINATION_SUCCESS: 'DELETE_DESTINATION_SUCCESS',
	DELETE_DESTINATION_FAILURE: 'DELETE_DESTINATION_FAILURE',

	GET_DESTINATIONS_REQUEST: 'GET_DESTINATIONS_REQUEST',
	GET_DESTINATIONS_SUCCESS: 'GET_DESTINATIONS_SUCCESS',
	GET_DESTINATIONS_FAILURE: 'GET_DESTINATIONS_FAILURE',

	GET_REORDERS_SUCCESS: 'GET_REORDERS_SUCCESS',
	GET_REORDERS_FAILURE: 'GET_REORDERS_FAILURE',

	GET_REORDER_SUCCESS: 'GET_REORDER_SUCCESS',
	GET_REORDER_FAILURE: 'GET_REORDER_FAILURE',

	STORE_IMAGE_SUCCESS: 'STORE_IMAGE_SUCCESS',
	STORE_IMAGE_FAILURE: 'STORE_IMAGE_FAILURE',

	GET_ALL_ORDERS_WITH_STATUS_REQUEST: 'GET_ALL_ORDERS_WITH_STATUS_REQUEST',
	GET_ALL_ORDERS_WITH_STATUS_SUCCESS: 'GET_ALL_ORDERS_WITH_STATUS_SUCCESS',
	GET_ALL_ORDERS_WITH_STATUS_FAILURE: 'GET_ALL_ORDERS_WITH_STATUS_FAILURE',

	IMAGE_PROCESSING_DOWNLOAD_FILE_REQUEST: 'IMAGE_PROCESSING_DOWNLOAD_FILE_REQUEST',
	IMAGE_PROCESSING_DOWNLOAD_FILE_SUCCESS: 'IMAGE_PROCESSING_DOWNLOAD_FILE_SUCCESS',
	IMAGE_PROCESSING_DOWNLOAD_FILE_FAILURE: 'IMAGE_PROCESSING_DOWNLOAD_FILE_FAILURE',

	GET_FLAGGED_ORDERS_SUCCESS: 'GET_FLAGGED_ORDERS_SUCCESS',
	GET_FLAGGED_ORDERS_FAILURE: 'GET_FLAGGED_ORDERS_FAILURE',

	GET_MULTIPLE_SHIPMENT_ORDERS_SUCCESS: 'GET_MULTIPLE_SHIPMENT_ORDERS_SUCCESS',
	GET_MULTIPLE_SHIPMENT_ORDERS_FAILURE: 'GET_MULTIPLE_SHIPMENT_ORDERS_FAILURE',

	GET_CUSTOM_MUSIC_SUCCESS: 'GET_CUSTOM_MUSIC_SUCCESS',
	GET_CUSTOM_MUSIC_FAILURE: 'GET_CUSTOM_MUSIC_FAILURE',

	// CHANGE_TRAVELER_SEE_EVERYONE_STATUS_REQUEST:'',
	// CHANGE_TRAVELER_SEE_EVERYONE_STATUS_SUCCESS:'',
	// CHANGE_TRAVELER_SEE_EVERYONE_STATUS_FAILURE:'',

	// CHANGE_ALL_USERS_MAP_STATUS_REQUEST:'',
	// CHANGE_ALL_USERS_MAP_STATUS_SUCCESS:'',
	// CHANGE_ALL_USERS_MAP_STATUS_FAILURE:'',

	GET_RANGES_REQUEST: 'GET_RANGES_REQUEST',
	GET_RANGES_SUCCESS: 'GET_RANGES_SUCCESS',
	GET_RANGES_FAILURE: 'GET_RANGES_FAILURE',

	STORE_PRO_SHOW_FILE_REQUEST: 'STORE_PRO_SHOW_FILE_REQUEST',
	STORE_PRO_SHOW_FILE_SUCCESS: 'STORE_PRO_SHOW_FILE_SUCCESS',
	STORE_PRO_SHOW_FILE_FAILURE: 'STORE_PRO_SHOW_FILE_FAILURE',

	GET_PRO_SHOW_FILE_REQUEST: 'GET_PRO_SHOW_FILE_REQUEST',
	GET_PRO_SHOW_FILE_SUCCESS: 'GET_PRO_SHOW_FILE_SUCCESS',
	GET_PRO_SHOW_FILE_FAILURE: 'GET_PRO_SHOW_FILE_FAILURE',

	PAGINATE_PRO_SHOW_FILE_REQUEST: 'PAGINATE_PRO_SHOW_FILE_REQUEST',
	PAGINATE_PRO_SHOW_FILE_SUCCESS: 'PAGINATE_PRO_SHOW_FILE_SUCCESS',
	PAGINATE_PRO_SHOW_FILE_FAILURE: 'PAGINATE_PRO_SHOW_FILE_FAILURE',

	PRO_SHOW_FILE_OPTIONS_REQUEST: 'PRO_SHOW_FILE_OPTIONS_REQUEST',
	PRO_SHOW_FILE_OPTIONS_SUCCESS: 'PRO_SHOW_FILE_OPTIONS_SUCCESS',
	PRO_SHOW_FILE_OPTIONS_FAILURE: 'PRO_SHOW_FILE_OPTIONS_FAILURE',

	UPDATE_PRO_SHOW_FILE_REQUEST: 'UPDATE_PRO_SHOW_FILE_REQUEST',
	UPDATE_PRO_SHOW_FILE_SUCCESS: 'UPDATE_PRO_SHOW_FILE_SUCCESS',
	UPDATE_PRO_SHOW_FILE_FAILURE: 'UPDATE_PRO_SHOW_FILE_FAILURE',

	DELETE_PRO_SHOW_FILE_REQUEST: 'DELETE_PRO_SHOW_FILE_REQUEST',
	DELETE_PRO_SHOW_FILE_SUCCESS: 'DELETE_PRO_SHOW_FILE_SUCCESS',
	DELETE_PRO_SHOW_FILE_FAILURE: 'DELETE_PRO_SHOW_FILE_FAILURE',

	GET_CONTACT_LIST_REQUEST: 'GET_CONTACT_LIST_REQUEST',
	GET_CONTACT_LIST_SUCCESS: 'GET_CONTACT_LIST_SUCCESS',
	GET_CONTACT_LIST_FAILURE: 'GET_CONTACT_LIST_FAILURE',

	ORDERS_FLYERS_LIST_SUCCESS: 'ORDERS_FLYERS_LIST_SUCCESS',
	ORDERS_FLYERS_LIST_FAILURE: 'ORDERS_FLYERS_LIST_FAILURE',

	GET_PREAWARENESS_FLYERS_HISTORY_SUCCESS: 'GET_PREAWARENESS_FLYERS_HISTORY_SUCCESS',
	GET_PREAWARENESS_FLYERS_HISTORY_FAILURE: 'GET_PREAWARENESS_FLYERS_HISTORY_FAILURE',

	GET_PREAWARENESS_AGENCIES_SUCCESS: 'GET_PREAWARENESS_AGENCIES_SUCCESS',
	GET_PREAWARENESS_AGENCIES_FAILURE: 'GET_PREAWARENESS_AGENCIES_FAILURE',

	GET_UI_SETTINGS_REQUEST: 'GET_UI_SETTINGS_REQUEST',
	GET_UI_SETTINGS_SUCCESS: 'GET_UI_SETTINGS_SUCCESS',
	GET_UI_SETTINGS_FAILURE: 'GET_UI_SETTINGS_FAILURE',

	GET_ALL_MOSAIC_STYLES_SUCCESS: 'GET_ALL_MOSAIC_STYLES_SUCCESS',
	GET_ALL_MOSAIC_STYLES_FAILURE: 'GET_ALL_MOSAIC_STYLES_FAILURE',

	GET_ORDER_MOSAIC_SUCCESS: 'GET_ORDER_MOSAIC_SUCCESS',
	GET_ORDER_MOSAIC_FAILURE: 'GET_ORDER_MOSAIC_FAILURE',


	PAGINATION_BRANDS_REQUEST: 'PAGINATION_BRANDS_REQUEST',
	PAGINATION_BRANDS_SUCCESS: 'PAGINATION_BRANDS_SUCCESS',
	PAGINATION_BRANDS_FAILURE: 'PAGINATION_BRANDS_FAILURE',

	STORE_BRAND_REQUEST: 'STORE_BRAND_REQUEST',
	STORE_BRAND_SUCCESS: 'STORE_BRAND_SUCCESS',
	STORE_BRAND_FAILURE: 'STORE_BRAND_FAILURE',

	GET_BRAND_REQUEST: 'GET_BRAND_REQUEST',
	GET_BRAND_SUCCESS: 'GET_BRAND_SUCCESS',
	GET_BRAND_FAILURE: 'GET_BRAND_FAILURE',

	EDIT_BRAND_REQUEST: 'EDIT_BRAND_REQUEST',
	EDIT_BRAND_SUCCESS: 'EDIT_BRAND_SUCCESS',
	EDIT_BRAND_FAILURE: 'EDIT_BRAND_FAILURE',

	DELETE_BRAND_REQUEST: 'DELETE_BRAND_REQUEST',
	DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
	DELETE_BRAND_FAILURE: 'DELETE_BRAND_FAILURE',

	GET_BRANDS_REQUEST: 'GET_BRANDS_REQUEST',
	GET_BRANDS_SUCCESS: 'GET_BRANDS_SUCCESS',
	GET_BRANDS_FAILURE: 'GET_BRANDS_FAILURE',

	PAGINATION_FAQS_REQUEST: 'PAGINATION_FAQS_REQUEST',
	PAGINATION_FAQS_SUCCESS: 'PAGINATION_FAQS_SUCCESS',
	PAGINATION_FAQS_FAILURE: 'PAGINATION_FAQS_FAILURE',

	STORE_FAQ_REQUEST: 'STORE_FAQ_REQUEST',
	STORE_FAQ_SUCCESS: 'STORE_FAQ_SUCCESS',
	STORE_FAQ_FAILURE: 'STORE_FAQ_FAILURE',

	GET_FAQ_REQUEST: 'GET_FAQ_REQUEST',
	GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
	GET_FAQ_FAILURE: 'GET_FAQ_FAILURE',

	EDIT_FAQ_REQUEST: 'EDIT_FAQ_REQUEST',
	EDIT_FAQ_SUCCESS: 'EDIT_FAQ_SUCCESS',
	EDIT_FAQ_FAILURE: 'EDIT_FAQ_FAILURE',

	DELETE_FAQ_REQUEST: 'DELETE_FAQ_REQUEST',
	DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
	DELETE_FAQ_FAILURE: 'DELETE_FAQ_FAILURE',

	GET_FAQS_REQUEST: 'GET_FAQS_REQUEST',
	GET_FAQS_SUCCESS: 'GET_FAQS_SUCCESS',
	GET_FAQS_FAILURE: 'GET_FAQS_FAILURE',

	GET_FAQS_CATEGORIES_REQUEST: 'GET_FAQS_CATEGORIES_REQUEST',
	GET_FAQS_CATEGORIES_SUCCESS: 'GET_FAQS_CATEGORIES_SUCCESS',
	GET_FAQS_CATEGORIES_FAILURE: 'GET_FAQS_CATEGORIES_FAILURE',

	UPDATE_FAQ_STATUS_REQUEST: 'UPDATE_FAQ_STATUS_REQUEST',
	UPDATE_FAQ_STATUS_SUCCESS: 'UPDATE_FAQ_STATUS_SUCCESS',
	UPDATE_FAQ_STATUS_FAILURE: 'UPDATE_FAQ_STATUS_FAILURE',

	GET_ORDER_MATERIALS_SUCCESS: 'GET_ORDER_MATERIALS_SUCCESS',
	GET_ORDER_MATERIALS_FAILURE: 'GET_ORDER_MATERIALS_FAILURE',

	PAGINATION_TRANSMITTAL_TEMPLATES_REQUEST: 'PAGINATION_TRANSMITTAL_TEMPLATES_REQUEST',
	PAGINATION_TRANSMITTAL_TEMPLATES_SUCCESS: 'PAGINATION_TRANSMITTAL_TEMPLATES_SUCCESS',
	PAGINATION_TRANSMITTAL_TEMPLATES_FAILURE: 'PAGINATION_TRANSMITTAL_TEMPLATES_FAILURE',

	STORE_TRANSMITTAL_TEMPLATE_REQUEST: 'STORE_TRANSMITTAL_TEMPLATE_REQUEST',
	STORE_TRANSMITTAL_TEMPLATE_SUCCESS: 'STORE_TRANSMITTAL_TEMPLATE_SUCCESS',
	STORE_TRANSMITTAL_TEMPLATE_FAILURE: 'STORE_TRANSMITTAL_TEMPLATE_FAILURE',

	GET_TRANSMITTAL_TEMPLATE_REQUEST: 'GET_TRANSMITTAL_TEMPLATE_REQUEST',
	GET_TRANSMITTAL_TEMPLATE_SUCCESS: 'GET_TRANSMITTAL_TEMPLATE_SUCCESS',
	GET_TRANSMITTAL_TEMPLATE_FAILURE: 'GET_TRANSMITTAL_TEMPLATE_FAILURE',

	EDIT_TRANSMITTAL_TEMPLATE_REQUEST: 'EDIT_TRANSMITTAL_TEMPLATE_REQUEST',
	EDIT_TRANSMITTAL_TEMPLATE_SUCCESS: 'EDIT_TRANSMITTAL_TEMPLATE_SUCCESS',
	EDIT_TRANSMITTAL_TEMPLATE_FAILURE: 'EDIT_TRANSMITTAL_TEMPLATE_FAILURE',

	DELETE_TRANSMITTAL_TEMPLATE_REQUEST: 'DELETE_TRANSMITTAL_TEMPLATE_REQUEST',
	DELETE_TRANSMITTAL_TEMPLATE_SUCCESS: 'DELETE_TRANSMITTAL_TEMPLATE_SUCCESS',
	DELETE_TRANSMITTAL_TEMPLATE_FAILURE: 'DELETE_TRANSMITTAL_TEMPLATE_FAILURE',

	GET_TRANSMITTAL_TEMPLATES_REQUEST: 'GET_TRANSMITTAL_TEMPLATES_REQUEST',
	GET_TRANSMITTAL_TEMPLATES_SUCCESS: 'GET_TRANSMITTAL_TEMPLATES_SUCCESS',
	GET_TRANSMITTAL_TEMPLATES_FAILURE: 'GET_TRANSMITTAL_TEMPLATES_FAILURE',

	GET_TRANSMITTAL_TEMPLATES_CATEGORIES_REQUEST: 'GET_TRANSMITTAL_TEMPLATES_CATEGORIES_REQUEST',
	GET_TRANSMITTAL_TEMPLATES_CATEGORIES_SUCCESS: 'GET_TRANSMITTAL_TEMPLATES_CATEGORIES_SUCCESS',
	GET_TRANSMITTAL_TEMPLATES_CATEGORIES_FAILURE: 'GET_TRANSMITTAL_TEMPLATES_CATEGORIES_FAILURE',

	GET_TRANSMITTAL_TEMPLATES_TYPES_REQUEST: 'GET_TRANSMITTAL_TEMPLATES_TYPES_REQUEST',
	GET_TRANSMITTAL_TEMPLATES_TYPES_SUCCESS: 'GET_TRANSMITTAL_TEMPLATES_TYPES_SUCCESS',
	GET_TRANSMITTAL_TEMPLATES_TYPES_FAILURE: 'GET_TRANSMITTAL_TEMPLATES_TYPES_FAILURE',

	UPDATE_TRANSMITTAL_TEMPLATE_STATUS_REQUEST: 'UPDATE_TRANSMITTAL_TEMPLATE_STATUS_REQUEST',
	UPDATE_TRANSMITTAL_TEMPLATE_STATUS_SUCCESS: 'UPDATE_TRANSMITTAL_TEMPLATE_STATUS_SUCCESS',
	UPDATE_TRANSMITTAL_TEMPLATE_STATUS_FAILURE: 'UPDATE_TRANSMITTAL_TEMPLATE_STATUS_FAILURE',

	GET_ORDER_STATUSES_SUCCESS: 'GET_ORDER_STATUSES_SUCCESS',
	GET_ORDER_STATUSES_FAILURE: 'GET_ORDER_STATUSES_FAILURE',

	UPDATE_BRAND_STATUS_REQUEST: 'UPDATE_BRAND_STATUS_REQUEST',
	UPDATE_BRAND_STATUS_SUCCESS: 'UPDATE_BRAND_STATUS_SUCCESS',
	UPDATE_BRAND_STATUS_FAILURE: 'UPDATE_BRAND_STATUS_FAILURE',

	GET_BRAND_APP_SETTING_REQUEST: 'GET_BRAND_APP_SETTING_REQUEST',
	GET_BRAND_APP_SETTING_SUCCESS: 'GET_BRAND_APP_SETTING_SUCCESS',
	GET_BRAND_APP_SETTING_FAILURE: 'GET_BRAND_APP_SETTING_FAILURE',

	UPDATE_APP_SETTINGS_REQUEST: 'UPDATE_APP_SETTINGS_REQUEST',
	UPDATE_APP_SETTINGS_SUCCESS: 'UPDATE_APP_SETTINGS_SUCCESS',
	UPDATE_APP_SETTINGS_FAILURE: 'UPDATE_APP_SETTINGS_FAILURE',

	GET_ORDER_MOSAICS_SUCCESS: 'GET_ORDER_MOSAICS_SUCCESS',
	GET_ORDER_MOSAICS_FAILURE: 'GET_ORDER_MOSAICS_FAILURE',

	GET_DEFAULT_USER_PREFERENCE_REQUEST: 'GET_DEFAULT_USER_PREFERENCE_REQUEST',
	GET_DEFAULT_USER_PREFERENCE_SUCCESS: 'GET_DEFAULT_USER_PREFERENCE_SUCCESS',
	GET_DEFAULT_USER_PREFERENCE_FAILURE: 'GET_DEFAULT_USER_PREFERENCE_FAILURE',

	GET_ALL_VIDEO_SHIPMENT_REQUEST: 'GET_ALL_VIDEO_SHIPMENT_REQUEST',
	GET_ALL_VIDEO_SHIPMENT_SUCCESS: 'GET_ALL_VIDEO_SHIPMENT_SUCCESS',
	GET_ALL_VIDEO_SHIPMENT_FAILURE: 'GET_ALL_VIDEO_SHIPMENT_FAILURE',

	GET_ALL_TODAYS_SHIPMENT_REQUEST: 'GET_ALL_TODAYS_SHIPMENT_REQUEST',
	GET_ALL_TODAYS_SHIPMENT_SUCCESS: 'GET_ALL_TODAYS_SHIPMENT_SUCCESS',
	GET_ALL_TODAYS_SHIPMENT_FAILURE: 'GET_ALL_TODAYS_SHIPMENT_FAILURE',

	GET_ALL_WEB_LOG_REQUEST: 'GET_ALL_WEB_LOG_REQUEST',
	GET_ALL_WEB_LOG_SUCCESS: 'GET_ALL_WEB_LOG_SUCCESS',
	GET_ALL_WEB_LOG_FAILURE: 'GET_ALL_WEB_LOG_FAILURE',

	GET_ALL_CONTACT_LOG_REQUEST: 'GET_ALL_CONTACT_LOG_REQUEST',
	GET_ALL_CONTACT_LOG_SUCCESS: 'GET_ALL_CONTACT_LOG_SUCCESS',
	GET_ALL_CONTACT_LOG_FAILURE: 'GET_ALL_CONTACT_LOG_FAILURE',

	GET_ALL_ACCESS_LOG_REQUEST: 'GET_ALL_ACCESS_LOG_REQUEST',
	GET_ALL_ACCESS_LOG_SUCCESS: 'GET_ALL_ACCESS_LOG_SUCCESS',
	GET_ALL_ACCESS_LOG_FAILURE: 'GET_ALL_ACCESS_LOG_FAILURE',

	GET_ALL_UCD_REACHED_REQUEST: 'GET_ALL_UCD_REACHED_REQUEST',
	GET_ALL_UCD_REACHED_SUCCESS: 'GET_ALL_UCD_REACHED_SUCCESS',
	GET_ALL_UCD_REACHED_FAILURE: 'GET_ALL_UCD_REACHED_FAILURE',

	GET_ALL_ORDER_BOOKING_REQUEST: 'GET_ALL_ORDER_BOOKING_REQUEST',
	GET_ALL_ORDER_BOOKING_SUCCESS: 'GET_ALL_ORDER_BOOKING_SUCCESS',
	GET_ALL_ORDER_BOOKING_FAILURE: 'GET_ALL_ORDER_BOOKING_FAILURE',

	GET_ALL_SALES_FRECAST_REQUEST: 'GET_ALL_SALES_FRECAST_REQUEST',
	GET_ALL_SALES_FRECAST_SUCCESS: 'GET_ALL_SALES_FRECAST_SUCCESS',
	GET_ALL_SALES_FRECAST_FAILURE: 'GET_ALL_SALES_FRECAST_FAILURE',

	UPDATE_ORDER_PROSHOW_REQUEST: 'UPDATE_ORDER_PROSHOW_REQUEST',
	UPDATE_ORDER_PROSHOW_SUCCESS: 'UPDATE_ORDER_PROSHOW_SUCCESS',
	UPDATE_ORDER_PROSHOW_FAILURE: 'UPDATE_ORDER_PROSHOW_FAILURE',

	STORE_ADVANCE_SEARCH_REQUEST: 'STORE_ADVANCE_SEARCH_REQUEST',
	STORE_ADVANCE_SEARCH_SUCCESS: 'STORE_ADVANCE_SEARCH_SUCCESS',
	STORE_ADVANCE_SEARCH_FAILURE: 'STORE_ADVANCE_SEARCH_FAILURE',

	GET_INVOICING_PERIOD_REQUEST: 'GET_INVOICING_PERIOD_REQUEST',
	GET_INVOICING_PERIOD_SUCCESS: 'GET_INVOICING_PERIOD_SUCCESS',
	GET_INVOICING_PERIOD_FAILURE: 'GET_INVOICING_PERIOD_FAILURE',

	GET_INVOICING_ORDERS_REQUEST: 'GET_INVOICING_ORDERS_REQUEST',
	GET_INVOICING_ORDERS_SUCCESS: 'GET_INVOICING_ORDERS_SUCCESS',
	GET_INVOICING_ORDERS_FAILURE: 'GET_INVOICING_ORDERS_FAILURE',

	STORE_INVOICING_ORDERS_REQUEST: 'STORE_INVOICING_ORDERS_REQUEST',
	STORE_INVOICING_ORDERS_SUCCESS: 'STORE_INVOICING_ORDERS_SUCCESS',
	STORE_INVOICING_ORDERS_FAILURE: 'STORE_INVOICING_ORDERS_FAILURE',

	GET_RECENTLY_INVOICED_ORDERS_REQUEST: 'GET_RECENTLY_INVOICED_ORDERS_REQUEST',
	GET_RECENTLY_INVOICED_ORDERS_SUCCESS: 'GET_RECENTLY_INVOICED_ORDERS_SUCCESS',
	GET_RECENTLY_INVOICED_ORDERS_FAILURE: 'GET_RECENTLY_INVOICED_ORDERS_FAILURE',

	STORE_RECENTLY_INVOICED_ORDERS_REQUEST: 'STORE_RECENTLY_INVOICED_ORDERS_REQUEST',
	STORE_RECENTLY_INVOICED_ORDERS_SUCCESS: 'STORE_RECENTLY_INVOICED_ORDERS_SUCCESS',
	STORE_RECENTLY_INVOICED_ORDERS_FAILURE: 'STORE_RECENTLY_INVOICED_ORDERS_FAILURE',

	GET_INVOICING_SETTING_REQUEST: 'GET_INVOICING_SETTING_REQUEST',
	GET_INVOICING_SETTING_SUCCESS: 'GET_INVOICING_SETTING_SUCCESS',
	GET_INVOICING_SETTING_FAILURE: 'GET_INVOICING_SETTING_FAILURE',

	GET_PENDING_ORDERS_REQUEST: 'GET_PENDING_ORDERS_REQUEST',
	GET_PENDING_ORDERS_SUCCESS: 'GET_PENDING_ORDERS_SUCCESS',
	GET_PENDING_ORDERS_FAILURE: 'GET_PENDING_ORDERS_FAILURE',

	GET_TTK_PENDING_ORDERS_REQUEST: 'GET_TTK_PENDING_ORDERS_REQUEST',
	GET_TTK_PENDING_ORDERS_SUCCESS: 'GET_TTK_PENDING_ORDERS_SUCCESS',
	GET_TTK_PENDING_ORDERS_FAILURE: 'GET_TTK_PENDING_ORDERS_FAILURE',

	STORE_PENDING_ORDERS_REQUEST: 'STORE_PENDING_ORDERS_REQUEST',
	STORE_PENDING_ORDERS_SUCCESS: 'STORE_PENDING_ORDERS_SUCCESS',
	STORE_PENDING_ORDERS_FAILURE: 'STORE_PENDING_ORDERS_FAILURE',

	STORE_INVOICING_SETTING_REQUEST: 'STORE_INVOICING_SETTING_REQUEST',
	STORE_INVOICING_SETTING_SUCCESS: 'STORE_INVOICING_SETTING_SUCCESS',
	STORE_INVOICING_SETTING_FAILURE: 'STORE_INVOICING_SETTING_FAILURE',

	GET_INVOICING_IMPORT_REQUEST: 'GET_INVOICING_IMPORT_REQUEST',
	GET_INVOICING_IMPORT_SUCCESS: 'GET_INVOICING_IMPORT_SUCCESS',
	GET_INVOICING_IMPORT_FAILURE: 'GET_INVOICING_IMPORT_FAILURE',

	STORE_INVOICING_IMPORT_REQUEST: 'STORE_INVOICING_IMPORT_REQUEST',
	STORE_INVOICING_IMPORT_SUCCESS: 'STORE_INVOICING_IMPORT_SUCCESS',
	STORE_INVOICING_IMPORT_FAILURE: 'STORE_INVOICING_IMPORT_FAILURE',

	STORE_INVOICING_PRINT_REQUEST: 'STORE_INVOICING_PRINT_REQUEST',
	STORE_INVOICING_PRINT_SUCCESS: 'STORE_INVOICING_PRINT_SUCCESS',
	STORE_INVOICING_PRINT_FAILURE: 'STORE_INVOICING_PRINT_FAILURE',

	SET_UNPROCESS_ORDERS_REQUEST: 'SET_UNPROCESS_ORDERS_REQUEST',
	SET_UNPROCESS_ORDERS_SUCCESS: 'SET_UNPROCESS_ORDERS_SUCCESS',
	SET_UNPROCESS_ORDERS_FAILURE: 'SET_UNPROCESS_ORDERS_FAILURE',
	//start
	GET_RECEIVING_REPORTS_REQUEST: 'GET_RECEIVING_REPORTS_REQUEST',
	GET_RECEIVING_REPORTS_SUCCESS: 'GET_RECEIVING_REPORTS_SUCCESS',
	GET_RECEIVING_REPORTS_FAILURE: 'GET_RECEIVING_REPORTS_FAILURE',

	CHECK_BARCODE_STATUS_REQUEST: 'CHECK_BARCODE_STATUS_REQUEST',
	CHECK_BARCODE_STATUS_SUCCESS: 'CHECK_BARCODE_STATUS_SUCCESS',
	CHECK_BARCODE_STATUS_FAILURE: 'GET_RECEIVING_REPORTS_FAILURE',
	//end
	DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
	DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
	DELETE_ORDER_FAILURE: 'DELETE_ORDER_FAILURE',

	ORDER_TO_MISSING_REQUEST: 'ORDER_TO_MISSING_REQUEST',
	ORDER_TO_MISSING_SUCCESS: 'ORDER_TO_MISSING_SUCCESS',
	ORDER_TO_MISSING_FAILURE: 'ORDER_TO_MISSING_FAILURE',

	CS_ORDERS_REQUEST: 'CS_ORDERS_REQUEST',
	CS_ORDERS_SUCCESS: 'CS_ORDERS_SUCCESS',
	CS_ORDERS_FAILURE: 'CS_ORDERS_FAILURE',


	VT_ORDERS_REQUEST: 'VT_ORDERS_REQUEST',
	VT_ORDERS_SUCCESS: 'VT_ORDERS_SUCCESS',
	VT_ORDERS_FAILURE: 'VT_ORDERS_FAILURE',


	UPDATE_ORDER_CSR_STEPS_SUCCESS: 'UPDATE_ORDER_CSR_STEPS_SUCCESS',


	GET_ALL_ORDER_LOG_REQUEST: 'GET_ALL_ORDER_LOG_REQUEST',
	GET_ALL_ORDER_LOG_SUCCESS: 'GET_ALL_ORDER_LOG_SUCCESS',
	GET_ALL_ORDER_LOG_FAILURE: 'GET_ALL_ORDER_LOG_FAILURE',

	UPDATE_PRODUCTION_DETAIL_SUCCESS: 'UPDATE_PRODUCTION_DETAIL_SUCCESS',

	//optimize
	// STORE_AGENCY_REQUEST: 'STORE_AGENCY_REQUEST',
	// STORE_AGENCY_SUCCESS: 'STORE_AGENCY_SUCCESS',
	// STORE_AGENCY_FAILURE: 'STORE_AGENCY_FAILURE',

	// EDIT_AGENCY_REQUEST: 'EDIT_AGENCY_REQUEST',
	// EDIT_AGENCY_SUCCESS: 'EDIT_AGENCY_SUCCESS',
	// EDIT_AGENCY_FAILURE: 'EDIT_AGENCY_FAILURE',

	// DELETE_AGENCY_REQUEST: 'DELETE_AGENCY_REQUEST',
	// DELETE_AGENCY_SUCCESS: 'DELETE_AGENCY_SUCCESS',
	// DELETE_AGENCY_FAILURE: 'DELETE_AGENCY_FAILURE',

	GET_AGENCY_REQUEST: 'GET_AGENCY_REQUEST',
	GET_AGENCY_SUCCESS: 'GET_AGENCY_SUCCESS',
	GET_AGENCY_FAILURE: 'GET_AGENCY_FAILURE',

	// PAGINATION_AGENCIES_REQUEST: 'PAGINATION_AGENCIES_REQUEST',
	// PAGINATION_AGENCIES_SUCCESS: 'PAGINATION_AGENCIES_SUCCESS',
	// PAGINATION_AGENCIES_FAILURE: 'PAGINATION_AGENCIES_FAILURE',
	TRAVELER_PASSWORD_UPDATE_SUCCESS: 'TRAVELER_PASSWORD_UPDATE_SUCCESS',
	TRAVELER_PASSWORD_UPDATE_FAILURE: 'TRAVELER_PASSWORD_UPDATE_FAILURE',

	GET_PRE_SHIPMENT_EMAIL_LIST_SUCCESS: 'GET_PRE_SHIPMENT_EMAIL_LIST_SUCCESS',
	GET_PRE_SHIPMENT_EMAIL_LIST_FAILURE: 'GET_PRE_SHIPMENT_EMAIL_LIST_FAILURE',

	GET_PRE_DEPARTURE_LIST_SUCCESS: 'GET_PRE_DEPARTURE_LIST_SUCCESS',
	GET_PRE_DEPARTURE_LIST_FAILURE: 'GET_PRE_DEPARTURE_LIST_LIST_FAILURE',

	GET_ORDER_CHECKIN_LIST_SUCCESS: 'GET_ORDER_CHECKIN_LIST_SUCCESS',
	GET_ORDER_CHECKIN_LIST_FAILURE: 'GET_ORDER_CHECKIN_LIST_FAILURE',

	SAVE_SEARCH_PARAMS: 'SAVE_SEARCH_PARAMS',

	UPDATE_SETTINGS_IN_ORDER_DETAIL_SUCCESS :'UPDATE_SETTINGS_IN_ORDER_DETAIL_SUCCESS',
	UPDATE_SETTINGS_IN_ORDER_DETAIL_FAILURE : 'UPDATE_SETTINGS_IN_ORDER_DETAIL_FAILURE',


	EDIT_ALL_DESTINATIONS_REQUEST: "EDIT_ALL_DESTINATIONS_REQUEST",
	EDIT_ALL_DESTINATIONS_SUCCESS: "EDIT_ALL_DESTINATIONS_SUCCESS",
	EDIT_ALL_DESTINATIONS_FAILURE: "EDIT_ALL_DESTINATIONS_FAILURE",

	GET_MORE_BUTTONS_PAGINATION_SUCCESS: "GET_MORE_BUTTONS_PAGINATION_SUCCESS",
	GET_ACCOUNT_MORE_BUTTONS_PAGINATION_SUCCESS: "GET_ACCOUNT_MORE_BUTTONS_PAGINATION_SUCCESS",

    STORE_MORE_BUTTON_SUCCESS: "STORE_MORE_BUTTON_SUCCESS",
	STORE_MORE_BUTTON_FAILURE: "STORE_MORE_BUTTON_FAILURE",

	GET_SINGLE_MORE_BUTTON_SUCCESS: "GET_SINGLE_MORE_BUTTON_SUCCESS",
    GET_SINGLE_MORE_BUTTON_FAILURE: "GET_SINGLE_MORE_BUTTON_FAILURE",

	UPDATE_MORE_BUTTON_SUCCESS: "UPDATE_MORE_BUTTON_SUCCESS",
	UPDATE_ACCOUNT_MORE_BUTTON_SUCCESS: "UPDATE_ACCOUNT_MORE_BUTTON_SUCCESS",
	UPDATE_MORE_BUTTON_FOR_ORDER_SUCCESS: "UPDATE_MORE_BUTTON_FOR_ORDER_SUCCESS",
	UPDATE_MORE_BUTTON_FAILURE: "UPDATE_MORE_BUTTON_FAILURE",
	UPDATE_ACCOUNT_MORE_BUTTON_FAILURE: "UPDATE_ACCOUNT_MORE_BUTTON_FAILURE",
	UPDATE_MORE_BUTTON_STATUS_REQUEST: "UPDATE_MORE_BUTTON_STATUS_REQUEST",
	UPDATE_MORE_BUTTON_STATUS_SUCCESS: "UPDATE_MORE_BUTTON_STATUS_SUCCESS",
	UPDATE_MORE_BUTTON_STATUS_FAILURE: "UPDATE_MORE_BUTTON_STATUS_FAILURE",

	DELETE_MORE_BUTTON_SUCCESS: "DELETE_MORE_BUTTON_SUCCESS",

	GET_MORE_BUTTON_FOR_ORDER_SUCCESS: "GET_MORE_BUTTON_FOR_ORDER_SUCCESS",
	GET_MORE_BUTTON_FOR_ORDER_FAILURE: "GET_MORE_BUTTON_FOR_ORDER_FAILURE",

	PAGINATION_AGENCY_DOCUMENTS_REQUEST: "PAGINATION_AGENCY_DOCUMENTS_REQUEST",
	PAGINATION_AGENCY_DOCUMENTS_SUCCESS: "PAGINATION_AGENCY_DOCUMENTS_SUCCESS",
	PAGINATION_AGENCY_DOCUMENTS_FAILURE: "PAGINATION_AGENCY_DOCUMENTS_FAILURE",

	STORE_AGENCY_DOCUMENTS_REQUEST: "STORE_AGENCY_DOCUMENTS_REQUEST",
	STORE_AGENCY_DOCUMENTS_SUCCESS: "STORE_AGENCY_DOCUMENTS_SUCCESS",
	STORE_AGENCY_DOCUMENTS_FAILURE: "STORE_AGENCY_DOCUMENTS_FAILURE",


	DELETE_AGENCY_DOCUMENTS_REQUEST: "DELETE_AGENCY_DOCUMENTS_REQUEST",
	DELETE_AGENCY_DOCUMENTS_SUCCESS: "DELETE_AGENCY_DOCUMENTS_SUCCESS",
	DELETE_AGENCY_DOCUMENTS_FAILURE: "DELETE_AGENCY_DOCUMENTS_FAILURE",

    STORE_ORDER_IMAGES_IN_DB_SUCCESS: "STORE_ORDER_IMAGES_IN_DB_SUCCESS",
    STORE_ORDER_IMAGES_IN_DB_FAILURE: "STORE_ORDER_IMAGES_IN_DB_FAILURE",

    STORE_ORDER_IMAGES_IN_DB_BY_FRONTEND_SUCCESS: "STORE_ORDER_IMAGES_IN_DB_BY_FRONTEND_SUCCESS",
    STORE_ORDER_IMAGES_IN_DB_BY_FRONTEND_FAILURE: "STORE_ORDER_IMAGES_IN_DB_BY_FRONTEND_FAILURE",

    UPDATE_IS_INVOICED_REQUEST: "UPDATE_IS_INVOICED_REQUEST",
    UPDATE_IS_INVOICED_SUCCESS: "UPDATE_IS_INVOICED_SUCCESS",
    UPDATE_IS_INVOICED_FAILURE: "UPDATE_IS_INVOICED_FAILURE",

    GET_IS_INVOICED_REQUEST: "GET_IS_INVOICED_REQUEST",
    GET_IS_INVOICED_SUCCESS: "GET_IS_INVOICED_SUCCESS",
    GET_IS_INVOICED_FAILURE: "GET_IS_INVOICED_FAILURE",

    ALLOW_SAVE_WORKING_IMAGES: "ALLOW_SAVE_WORKING_IMAGES",
    DISABLE_SAVE_WORKING_IMAGES: "DISABLE_SAVE_WORKING_IMAGES",


    SAVE_AGENCY_SEARCH_PARAMS: "SAVE_AGENCY_SEARCH_PARAMS",

    GET_AGENCY_USER_PREFERENCE_REQUEST: "GET_AGENCY_USER_PREFERENCE_REQUEST",
    GET_AGENCY_USER_PREFERENCE_SUCCESS: "GET_AGENCY_USER_PREFERENCE_SUCCESS",
    GET_AGENCY_USER_PREFERENCE_FAILURE: "GET_AGENCY_USER_PREFERENCE_FAILURE",

    STORE_AGENCY_PREFERENCE_REQUEST: "STORE_AGENCY_PREFERENCE_REQUEST",
    STORE_AGENCY_PREFERENCE_SUCCESS: "GET_AGENCY_USER_PREFERENCE_SUCCESS",
    STORE_AGENCY_PREFERENCE_FAILURE: "GET_AGENCY_USER_PREFERENCE_FAILURE",

    GET_AGENCY_DEFAULT_USER_PREFERENCE_REQUEST: 'GET_AGENCY_DEFAULT_USER_PREFERENCE_REQUEST',
    GET_AGENCY_DEFAULT_USER_PREFERENCE_SUCCESS: 'GET_AGENCY_DEFAULT_USER_PREFERENCE_SUCCESS',
    GET_AGENCY_DEFAULT_USER_PREFERENCE_FAILURE: 'GET_AGENCY_DEFAULT_USER_PREFERENCE_FAILURE',


	GET_PRO_SHOW_FILE_DESTINATION_REQUEST: "GET_PRO_SHOW_FILE_DESTINATION_REQUEST",
	GET_PRO_SHOW_FILE_DESTINATION_SUCCESS: "GET_PRO_SHOW_FILE_DESTINATION_SUCCESS",
	GET_PRO_SHOW_FILE_DESTINATION_FAILURE: "GET_PRO_SHOW_FILE_DESTINATION_FAILURE"

};
