import React, { Component } from 'react'
import { connect } from 'react-redux';
import $ from 'jquery';
import { Roles } from "constants/Roles";
import User from "helper/User";
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import { s3StaticPath } from 'helper/helperFunctions';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

// import MyImage from 'components/Upload/MyImage';

class UserHome extends Component {
  componentDidMount() {
    $(".faqList > .quest").click(function () {
      $(this).toggleClass("open");
      $(this).next(".ans").stop(true, true).slideToggle("fast");
    });

    $(window).resize(function () {
      setTimeout(function () {
        $("#how_it_works .banner").height($("#how_it_works .banner img").height());
      }, 300);
    });

    // var count = 10;
    $(window).scroll(function () {
      let a = $(this).scrollTop();
      a = a - (a / 5);
      $("#how_it_works .banner img").css({ position: 'relative', top: a + 'px' });
    });
  }
  render() {
    return (
      <main id="how_it_works">
        <section className="banner">
          <img alt="Banner header" src={s3StaticPath('img/works/wtk_photo_header.jpg')} />
        </section>

        <section >
          <div className="container mt-md">
            <div className="row">
              <div className="col-md-9">
                {
                  (
                    User.hasRole([Roles.PHOTO_ADMIN]) && !User.getProperty('cameraShipped') && User.getProperty('upload_approved') && Number(User.orderSettingValue('finalize_by_qa')) !== 1
                  ) &&
                  <h5 className="home_heading userLogin_heading twenty22">Your gallery will be available after your Pre-Trip Materials are sent.</h5>
                }
                <h2 className="home_heading">Features &amp; Benefits</h2>
              </div>
              {
                User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
                <div className="col-md-3 text-right">
                  <Link className="home_new_order_btn pt-sm pb-sm  hover_dark" to={'/new-order'} ><i style={{ verticalAlign: 'bottom' }} className="noLmargin flaticon-shopping-cart"></i>&nbsp; New Order</Link>
                </div>
              }
            </div>
          </div>
          <div className="container mt-md">
            <Row className="mb25 tabsContentUpdated">
              <Col xs={12} sm={4} className="mt10">
                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                  <img
                    src={s3StaticPath('img/home/slicing/wtk/1.png')}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay_home">
                    <img src={s3StaticPath('img/home/slicing/New-Free-Mobile-App.png')} alt="New-Free-Mobile-App" />
                  </div>
                  <div className="px1-25 py25 text-center" >
                    <h6 className="secondary font-weight-bold lineH wtk_heading">
                      Free Mobile App
                    </h6>
                    <span className="secondary mt25">
                      Allows connection amongst the travelers and the family &amp; friends back home with real time photo uploads while on tour.
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4} className="mt10">
                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                  <img
                    src={s3StaticPath('img/home/slicing/wtk/2.png')}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay_home">
                    <img src={s3StaticPath('img/home/slicing/Video-Downloads.png')} alt="Video-Downloads" height={60} />
                  </div>
                  <div className="px1-25 py25 text-center">
                    <h6 className="secondary font-weight-bold lineH wtk_heading">
                      Video Downloads
                    </h6>
                    <span className="secondary mt25">
                      Keepsake Video can be downloaded from the app or GroupTravelVideos.com gallery for offline viewing.
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4} className="mt10">
                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                  <img
                    src={s3StaticPath('img/home/slicing/wtk/3.png')}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay_home">
                    <img src={s3StaticPath('img/home/slicing/Video-Clips.png')} alt="Video-Clips" height={60} />
                  </div>
                  <div className="px1-25 py25 text-center">
                    <h6 className="secondary font-weight-bold lineH wtk_heading">
                      Video Clips and<br />Performance Music
                    </h6>
                    <span className="secondary mt25">
                      Travelers can include these in their videos for FREE
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb25 tabsContentUpdated">
              <Col xs={12} sm={4} className="mt10">
                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                  <img
                    src={s3StaticPath('img/home/slicing/wtk/4.png')}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay_home">
                    <img src={s3StaticPath('img/home/slicing/USB-Flash.png')} alt="USB-Flash" />
                  </div>
                  <div className="px1-25 py25 text-center" >
                    <h6 className="secondary font-weight-bold lineH wtk_heading">
                      USB Flash Drive for Group Leaders
                    </h6>
                    <span className="secondary mt25">
                      Your group leaders will have the photos and videos at their fingertips on a USB drive (Shipping within the US) Perfect for using during parent meetings to show to recruit future trips. These or DVDs can be optionally ordered for all travelers, too.
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4} className="mt10">
                <div className="border1 whiteBG" style={{ minHeight: '455px' }}>
                  <img
                    src={s3StaticPath('img/home/slicing/wtk/5.png')}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay_home">
                    <img src={s3StaticPath('img/home/slicing/Facebook-Sharing.png')} alt="Facebook-Sharing" height={60} />
                  </div>
                  <div className="px1-25 py25 text-center">
                    <h6 className="secondary font-weight-bold lineH wtk_heading">
                      Facebook Sharing
                    </h6>
                    <span className="secondary mt25">
                      Travelers can easily share photos &amp; their highlight video on Facebook from the app or GroupTravelVideos.com photo gallery.
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4} className="mt10">
                <div className="border1 whiteBG" style={{ minHeight: '455px' }}>
                  <img
                    src={s3StaticPath('img/home/slicing/wtk/6.png')}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay_home">
                    <img src={s3StaticPath('img/home/slicing/Photo-Editing.png')} alt="Photo-Editing" height={60} />
                  </div>
                  <div className="px1-25 py25 text-center">
                    <h6 className="secondary font-weight-bold lineH wtk_heading">
                      Photo Editing
                    </h6>
                    <span className="secondary mt25">
                      Our editors go through each photo to remove not needed or duplicate photos, do color correction, &amp; cropping.
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb25 tabsContentUpdated mb-md">
              <Col xs={12} sm={4} className="mt10">
                <div className="border1 whiteBG" style={{ minHeight: '440px' }}>
                  <img
                    src={s3StaticPath('img/home/slicing/wtk/7.png')}
                    alt="App Features 1"
                    className="mw100Force"
                  />
                  <div className="roundedOverlay_home">
                    <img src={s3StaticPath('img/home/slicing/Advertising-&-Marketing.png')} alt="Advertising-&-Marketing" />
                  </div>
                  <div className="px1-25 py25 text-center" >
                    <h6 className="secondary font-weight-bold lineH wtk_heading">
                      Advertising &amp; Marketing
                    </h6>
                    <span className="secondary mt25">
                      It's a commercial for your tours funded by your travelers. Each time a traveler logs in to the app or website they will see your company's logo as well as at the beginning &amp; end of each video. It serves as a great recruiting tool for Group Leaders, teachers &amp; band directors for next year's trip. They show it at the pre-trip meetings which excites everyone about traveling.
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>






























      </main>
      // <div className="container">
      //     <div className="row">
      //         <div className="col-md-12">
      //             {
      //                 (
      //                     User.hasRole([Roles.PHOTO_ADMIN]) && !User.getProperty('cameraShipped') && User.getProperty('upload_approved')  && Number(User.orderSettingValue('finalize_by_qa')) !== 1
      //                 ) &&
      //                     <h5 className="panel_heading userLogin_heading twenty22">Your gallery will be available after your Pre-Trip Materials are sent.</h5>
      //             }
      //             <h2 className="panel_heading userLogin_heading">Features &amp; Benefits</h2>
      //         </div>
      //     </div>

      //     <div className="row col-md-12">
      //         {
      //             User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
      //             <div className="">
      //                 <Link className="themeBtn_14 mt-xlg pt-sm pb-sm purple_gradient hover_dark" to={'/new-order'} ><i style={{ verticalAlign: 'bottom' }} className="noLmargin flaticon-shopping-cart"></i>&nbsp; New Order</Link>
      //             </div>
      //         }
      //         <div className="features_grid">
      //             <div className="featureBox useBox">
      //                 <div>
      //                     <img src={s3StaticPath('img/new_feature.png')} alt="New Free Mobile App" />
      //                 </div>

      //                 <h2>Free Mobile App</h2>

      //                 <p className="text_16">
      //                     {/* Allows interaction with family &amp; friends back home with real time photo uploads while on tour. */}
      //                     Allows connection amongst the travelers and the family &amp; friends back home with real time photo uploads while on tour.
      //                     </p>
      //             </div>

      //             <div className="featureBox useBox">
      //                 <div>
      //                     <img src={s3StaticPath('img/video_downloads.png')} alt="Video Downloads" />
      //                 </div>

      //                 <h2>Video Downloads</h2>

      //                 <p className="text_16">
      //                     {/* Videos can be downloaded from the app or GroupTravelVideos.com gallery for offline viewing. */}
      //                     Keepsake Video can be downloaded from the app or GroupTravelVideos.com gallery for offline viewing.
      //                     </p>
      //             </div>

      //             <div className="featureBox useBox">
      //                 <div>
      //                     <img src={s3StaticPath('img/video_clips.png')} alt="Video Clips and Performance Music" />
      //                 </div>

      //                 <h2>Video Clips and<br />Performance Music</h2>

      //                 <p className="text_16">
      //                     Travelers can include these in their videos for FREE
      //                     </p>
      //             </div>
      //             {
      //                 /*
      //             <div className="featureBox useBox">
      //                 <div>
      //                     <img src={s3StaticPath('img/dvd_video.png')} alt="Dvd Video" />
      //                 </div>

      //                 <h2>Dvd Video</h2>

      //                 <p className="text_16">
      //                     Each traveler receives a video on DVD to keep and preserve their memories for years to come! {
      //                         User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
      //                         'DVDs & DVD sleeves can be desgined with your company colors, logo, & info for FREE by our design department. Just ask about our partner program.'
      //                     }
      //                 </p>
      //             </div>
      //             */
      //             }

      //             <div className="featureBox useBox">
      //                 <div>
      //                     <img src={s3StaticPath('img/usb_drive.png')} alt="Dvd Video" />
      //                 </div>

      //                 <h2>USB Flash Drive for Group Leaders</h2>

      //                 <p className="text_16">
      //                     Your group leaders will have the photos and videos at their fingertips on a USB drive (Shipping within the US) Perfect for using during parent meetings to show to recruit future trips. These or DVDs can be optionally ordered for all travelers, too.
      //                 </p>
      //             </div>

      //             <div className="featureBox useBox">
      //                 <div>
      //                     <img src={s3StaticPath('img/facebook_sharing.png')} alt="Facebook Sharing" />
      //                 </div>

      //                 <h2>Facebook Sharing</h2>

      //                 <p className="text_16">
      //                     Travelers can easily share photos &amp; their highlight video on Facebook from the app or GroupTravelVideos.com photo gallery.
      //                     </p>
      //             </div>

      //             <div className="featureBox useBox">
      //                 <div>
      //                     <img src={s3StaticPath('img/photo_editing.png')} alt="Editing" />
      //                 </div>

      //                 <h2>Photo Editing</h2>

      //                 <p className="text_16">
      //                     Our editors go through each photo to remove not needed or duplicate photos, do color correction, &amp; cropping.
      //                     </p>
      //             </div>

      //             {
      //                 User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
      //                 <div className="featureBox useBox">
      //                     <div>
      //                         <img src={s3StaticPath('img/advertising_marketing.png')} alt="Editing" />
      //                     </div>

      //                     <h2>Advertising &amp; Marketing</h2>

      //                     <p className="text_16">
      //                         It's a commercial for your tours funded by your travelers. Each time a traveler logs in to the app or website they will see your company's logo as well as at the beginning &amp; end of each video. It serves as a great recruiting tool for Group Leaders, teachers &amp; band directors for next year's trip. They show it at the pre-trip meetings which excites everyone about traveling.
      //                         </p>
      //                 </div>
      //             }
      //         </div>
      //     </div>
      // </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(UserHome));
