import React, { Component } from 'react'
import {OverlayTrigger, Tooltip} from "react-bootstrap";



class ImagesAi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            path: this.props.image.resized_image_path.replace('SIZE', this.props.imageSize),
        }
    }
    componentDidUpdate(prevProps) {
		if (this.props.imageSize !== prevProps.imageSize) {
            this.setState({
                path: this.props.image.resized_image_path.replace('SIZE', this.props.imageSize)
            })
        }
    }

	render() {
		const { image } = this.props;

		return (
			<div className=''>
				<div className='uploadedImageSize'>
					<img
						src={this.state.path + "?updated_at=" + image.updated_at}
						onError={this.getImagePath}
						alt="Text"
						// onClick={ () => {this.props.selectImageHandler(this.props.index)}}
					/>


				</div>
				{/* {
					this.props.showData &&
					<React.Fragment>
						<div className="galleryInfo p-sm">
							<OverlayTrigger placement="bottom" overlay={
								<Tooltip id={image.id}>
									Uploaded By
								</Tooltip>
							}>
								<span className="text_16">{image.uploader_name}</span>
							</OverlayTrigger>

							<OverlayTrigger placement="bottom" overlay={
								<Tooltip id={image.id}>
									Photo Name
								</Tooltip>
							}>
								<span
									className="text_14 galleryId">{Number(orderUploadInfo.finalize_by_qa.value) === 1 ? image.image_versions.file_label : image.id}</span>
							</OverlayTrigger>
						</div>

						{
							image.date_taken && User.hasRole(Roles.PHOTO_ADMIN) &&
							<div className="pl-sm pr-sm mb-md text_left_center_991">
								<OverlayTrigger placement="bottom" overlay={
									<Tooltip id={image.id}>
										Date
									</Tooltip>
								}>
									<span className="text_14 text-muted dark">{image.date_taken}</span>
								</OverlayTrigger>
							</div>
						}
					</React.Fragment>
				}
				{
					this.props.editedPhotoGallery &&
					<React.Fragment>
						<div className="photoGallery p-sm">
							<OverlayTrigger placement="bottom" overlay={
								<Tooltip id={image.id}>
									Photo Name
								</Tooltip>
							}>
								<p className="text_16">
									{image.image_versions.file_label}
								</p>
							</OverlayTrigger>

							<OverlayTrigger placement="bottom" overlay={
								<Tooltip id={image.id}>
									Uploaded by
								</Tooltip>
							}>
								<span className="text_14 galleryId mt-1">
									{image.is_web_image ? "web" : "app"}
								</span>
							</OverlayTrigger>
						</div>
					</React.Fragment>
				} */}
			</div>
		)
	}
}

export default ImagesAi;

