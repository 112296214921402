import React, { Component } from 'react';
import $ from 'jquery';
import { s3StaticPath } from '../../helper/helperFunctions';
import { Link } from 'react-router-dom';
import { Row, Col } from "react-bootstrap"
import ReactPlayer from 'react-player'
import { Parallax } from 'react-parallax';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ScrollAnimation from 'react-animate-on-scroll';
import "./../../assets/css/animate.min.css";
import "assets/css/wedding.css"

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { Grid } from '@mui/material';
class GtvHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      isPlaying: false,
      wtkVideo: false,
      testimonial1: true,
      testimonial2: false,
      testimonial3: false,
      testimonial4: false,
      testimonial5: false,
      testimonial6: false,
      testimonial7: false,
      testimonial8: false,
      testimonial9: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  videoPlay = (e) => {
    console.log("inside video play large", e);
    this.setState({ isPlaying: true })
  }
  videoApp = () => {
    this.setState({ wtkVideo: true })
  }
  testimonial1 = () => {
    this.setState({ testimonial1: !this.state.testimonial1 })
    this.setState({ testimonial2: false })
    this.setState({ testimonial3: false })
    this.setState({ testimonial4: false })
    this.setState({ testimonial5: false })
    this.setState({ testimonial6: false })
    this.setState({ testimonial7: false })
    this.setState({ testimonial8: false })
    this.setState({ testimonial9: false })
  }
  testimonial2 = () => {
    this.setState({ testimonial2: !this.state.testimonial2 })
    this.setState({ testimonial: false })
    this.setState({ testimonial3: false })
    this.setState({ testimonial4: false })
    this.setState({ testimonial5: false })
    this.setState({ testimonial6: false })
    this.setState({ testimonial7: false })
    this.setState({ testimonial8: false })
    this.setState({ testimonial9: false })
  }
  testimonial3 = () => {
    this.setState({ testimonial3: !this.state.testimonial3 })
    this.setState({ testimonial2: false })
    this.setState({ testimonial1: false })
    this.setState({ testimonial4: false })
    this.setState({ testimonial5: false })
    this.setState({ testimonial6: false })
    this.setState({ testimonial7: false })
    this.setState({ testimonial8: false })
    this.setState({ testimonial9: false })
  }
  testimonial4 = () => {
    this.setState({ testimonial4: !this.state.testimonial4 })
    this.setState({ testimonial2: false })
    this.setState({ testimonial3: false })
    this.setState({ testimonial1: false })
    this.setState({ testimonial5: false })
    this.setState({ testimonial6: false })
    this.setState({ testimonial7: false })
    this.setState({ testimonial8: false })
    this.setState({ testimonial9: false })
  }
  testimonial5 = () => {
    this.setState({ testimonial5: !this.state.testimonial5 })
    this.setState({ testimonial2: false })
    this.setState({ testimonial3: false })
    this.setState({ testimonial4: false })
    this.setState({ testimonial1: false })
    this.setState({ testimonial6: false })
    this.setState({ testimonial7: false })
    this.setState({ testimonial8: false })
    this.setState({ testimonial9: false })
  }
  testimonial6 = () => {
    this.setState({ testimonial6: !this.state.testimonial6 })
    this.setState({ testimonial2: false })
    this.setState({ testimonial3: false })
    this.setState({ testimonial4: false })
    this.setState({ testimonial5: false })
    this.setState({ testimonial1: false })
    this.setState({ testimonial7: false })
    this.setState({ testimonial8: false })
    this.setState({ testimonial9: false })
  }
  testimonial7 = () => {
    this.setState({ testimonial7: !this.state.testimonial7 })
    this.setState({ testimonial2: false })
    this.setState({ testimonial3: false })
    this.setState({ testimonial4: false })
    this.setState({ testimonial5: false })
    this.setState({ testimonial6: false })
    this.setState({ testimonial1: false })
    this.setState({ testimonial8: false })
    this.setState({ testimonial9: false })
  }
  testimonial8 = () => {
    this.setState({ testimonial8: !this.state.testimonial8 })
    this.setState({ testimonial2: false })
    this.setState({ testimonial3: false })
    this.setState({ testimonial4: false })
    this.setState({ testimonial5: false })
    this.setState({ testimonial6: false })
    this.setState({ testimonial7: false })
    this.setState({ testimonial1: false })
    this.setState({ testimonial9: false })
  }
  testimonial9 = () => {
    this.setState({ testimonial9: !this.state.testimonial9 })
    this.setState({ testimonial2: false })
    this.setState({ testimonial3: false })
    this.setState({ testimonial4: false })
    this.setState({ testimonial5: false })
    this.setState({ testimonial6: false })
    this.setState({ testimonial7: false })
    this.setState({ testimonial8: false })
    this.setState({ testimonial1: false })
  }
  handleClickSlider = (slide => {
    this.setState({ sliderActive: slide })
  })
  render() {
    const slickSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      prevArrow: false,
      nextArrow: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
      <div {...props}>{children}</div>
    );
    const { isPlaying } = this.state
    const { wtkVideo } = this.state
    const { testimonial1: testimonial } = this.state
    const { testimonial2: testimonial2 } = this.state
    const { testimonial3: testimonial3 } = this.state
    const { testimonial4: testimonial4 } = this.state
    const { testimonial5: testimonial5 } = this.state
    const { testimonial6: testimonial6 } = this.state
    const { testimonial7: testimonial7 } = this.state
    const { testimonial8: testimonial8 } = this.state
    const { testimonial9: testimonial9 } = this.state
    return (
      <main id="weddingTK" className='weddingTK'>
        <section className='heroBG'>
          <div className='container'>
            <Row>
              <Col md={6}>
                <h1 className='pollyBold text4xl mb2r'>Cherish Your Destination Wedding for a Lifetime.</h1>
                <Link to="#">
                  <img src={s3StaticPath('img/home/slicing/wtk/android.png')} alt={"Android"} />
                </Link>
                <Link to="#">
                  <img src={s3StaticPath('img/home/slicing/wtk/appstore.png')} alt={"appstore.png"} />
                </Link>
              </Col>
            </Row>
          </div>
        </section>

        <section className='weddingStory py-5r'>
          <div className='container'>
            <h2 className='pollyBold mainHeading text-center mb2r'>We will create a story of your wedding using shared photos from your guests.</h2>
            <Row>
              <Col md={6}>
                {
                  isPlaying ?
                    <div className='videoPlayer'>
                      <ReactPlayer
                        className="react-player"
                        url="https://gtvoriginalimages.s3.amazonaws.com/static/gtv_appDemo_apple.mp4"
                        width="100%" height={420}
                        controls
                        playing={true}
                        playIcon={true}
                        loop={false}
                        muted={false}
                        light={false}
                        pip={true}
                      />
                    </div>
                    :
                    <div className='playingBanner' onClick={this.videoPlay}>
                      <span className='playIcon'>
                        <img src={s3StaticPath('img/home/slicing/wtk/play-icon-black.svg')} alt={"play-icon-black.svg"} onClick={this.videoPlay} width={32} />
                      </span>
                      <img src={s3StaticPath('img/home/slicing/wtk/banner2.png')} alt={"VideoBanner"} onClick={this.videoPlay} className='vidBanner' />
                    </div>
                }

                <h3 className='pollyBold text-center'>A storytelling video</h3>
              </Col>
              <Col md={6}>
                <div class="side left"></div>
                <div class="side right"></div>
                <div class="wrapper">
                  <div class="front image"></div>
                </div>
                {/* <img src={Mosaix} alt={Mosaix} /> */}
                <h3 className='pollyBold text-center'>A magical mosaic of your experience</h3>
              </Col>
            </Row>
          </div>
        </section>

        <section className='features py-5r'>
          <div className='container'>
            <h2 className='pollyBold mainHeading mb2r text-center'>The App Features</h2>
            <div className='row'>
              <div className='col-12 col-md-6 col-lg-3'>
                <div className='featureItem'>
                  <figure>
                    <img src={s3StaticPath('img/home/slicing/wtk/communicate.png')} alt={"communicate"} />
                    <figcaption>
                      <div className='captionIcon'><img src={s3StaticPath('img/home/slicing/wtk/communicate-icon.svg')} alt={"communicateIcon"} width={24} /></div>
                      <h3 className='pollyBold text-center'>Communicate</h3>
                      <p>Stay connected with the wedding party and guests using the document sharing and messaging feature. Message everyone at one time, have important documents like the itinerary, excursions, and guest list.</p>
                    </figcaption>
                  </figure>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3'><div className='featureItem'>
                <figure>
                  <img src={s3StaticPath('img/home/slicing/wtk/share-memories.png')} alt={"shareMemories"} />
                  <figcaption>
                    <div className='captionIcon'><img src={s3StaticPath('img/home/slicing/wtk/memories-icon.svg')} alt={"shareMemoriesIcon"} width={24} /></div>
                    <h3 className='pollyBold text-center'>Share Memories</h3>
                    <p>You and guests privately share their photos of the awesome destination and experiences during your wedding. Family and friends at home follow along as if they were there.</p>
                  </figcaption>
                </figure>
              </div></div>
              <div className='col-12 col-md-6 col-lg-3'><div className='featureItem'>
                <figure>
                  <img src={s3StaticPath('img/home/slicing/wtk/enhance-safety.png')} alt={"enhanceSafety"} />
                  <figcaption>
                    <div className='captionIcon'><img src={s3StaticPath('img/home/slicing/wtk/safety-icon.svg')} alt={"enhanceSafetyIcon"} width={24} /></div>
                    <h3 className='pollyBold text-center'>Enhance Safety</h3>
                    <p>Attendee locator feature active during the dates of your wedding as an extra layer of security.</p>
                  </figcaption>
                </figure>
              </div></div>
              <div className='col-12 col-md-6 col-lg-3'><div className='featureItem'>
                <figure>
                  <img src={s3StaticPath('img/home/slicing/wtk/keepsake.png')} alt={"keepsake"} />
                  <figcaption>
                    <div className='captionIcon'><img src={s3StaticPath('img/home/slicing/wtk/keepsake-icon.svg')} alt={"keepsakeIcon"} width={24} /></div>
                    <h3 className='pollyBold text-center'>A Keepsake to Cherish</h3>
                    <p>This special time will be created into a story to last a lifetime! Easily relive this fun and loving experience with the Keepsake Video and 20x30 PhotoMozaix print we will be creating for you.</p>
                  </figcaption>
                </figure>
              </div></div>
            </div>
          </div>
        </section>

        <section className='wtkVideo py-5r'>
          <div className='container'>
            <h2 className='pollyBold mainHeading text-center mb2r'>Check out this short video to learn more about the Wedding Trip Kit App.</h2>
            {
              !wtkVideo && <div className='playingBanner' onClick={this.videoPlay}>
                <img src={s3StaticPath('img/home/slicing/wtk/video-banner.png')} alt={"WTKBanner"} onClick={this.videoApp} className='vidBanner' />
              </div>
            }
            {
              wtkVideo &&
              <div className='videoPlayer'>
                <ReactPlayer
                  className="react-player"
                  url="https://gtvoriginalimages.s3.amazonaws.com/static/gtv_appDemo_apple.mp4"
                  width="100%" height={630}
                  controls
                  playing={true}
                  playIcon={true}
                  loop={false}
                  muted={false}
                  light={false}
                  pip={true}
                />
              </div>
            }
          </div>
        </section>

        <section className='applications py-5r'>
          <div className='sliderFix'>
            <Slider {...slickSettings}>
              <div>
                <h3 className='pollyBold text-center mb1r'>Login</h3>
                <img src={s3StaticPath('img/home/slicing/wtk/login.png')} alt={"Login"} />
              </div>
              <div>
                <h3 className='pollyBold text-center mb1r'>Private Gallery</h3>
                <img src={s3StaticPath('img/home/slicing/wtk/gallery.png')} alt={"Gallery"} />
              </div>
              <div>
                <h3 className='pollyBold text-center mb1r'>Messaging</h3>
                <img src={s3StaticPath('img/home/slicing/wtk/messaging.png')} alt={'Messaging'} />
              </div>
              <div>
                <h3 className='pollyBold text-center mb1r'>Locator</h3>
                <img src={s3StaticPath('img/home/slicing/wtk/locator.png')} alt={'Locator'} />
              </div>
              <div>
                <h3 className='pollyBold text-center mb1r'>Newsfeed</h3>
                <img src={s3StaticPath('img/home/slicing/wtk/newsfeed.png')} alt={'Newsfeed'} />
              </div>
            </Slider>
          </div>
        </section>

        <section className='container py-5r'>
          <h2 className='pollyBold mainHeading text-center mb2r'>Start Sharing Today</h2>
          <Row className=''>
            <Col md={6} className='mb2r'>
              <div className='flex gap10p sharingItem rainbow'>
                <div className='QRCode'>
                  <img src={s3StaticPath('img/home/slicing/wtk/qr.svg')} alt={'QR'} className='' />
                </div>
                <div>
                  <h4 className='pollyBold'>Download App</h4>
                  <p>Call 888.533.7637 x213</p>
                  <p>to talk to ourteam to get started today, even if your trip is just days away.</p>
                  <img src={s3StaticPath('img/home/slicing/wtk/one.svg')} alt={'one'} className='sharingPos' />
                </div>
              </div>
            </Col>
            <Col md={6} className='mb2r'>
              <div className='flex gap10p sharingItem rainbow'>
                <img src={s3StaticPath('img/home/slicing/wtk/bg-15.png')} alt={"SharePrivate"} />
                <div>
                  <h4 className='pollyBold'>Share Privately</h4>
                  <p>Share the login with private codes provided to you by your destination resort.</p>
                  <img src={s3StaticPath('img/home/slicing/wtk/two.svg')} alt={"two"} className='sharingPos' />
                </div>
              </div>
            </Col>
            <Col md={6} className='mb2r'>
              <div className='flex gap10p sharingItem rainbow'>
                <img src={s3StaticPath('img/home/slicing/wtk/bg-14.png')} alt={"Documents"} />
                <div>
                  <h4 className='pollyBold'>Documents</h4>
                  <p>Use Document uploader, message guests and upload photos to the gallery.</p>
                  <img src={s3StaticPath('img/home/slicing/wtk/three.svg')} alt={'three'} className='sharingPos' />
                </div>
              </div>
            </Col>
            <Col md={6} className='mb2r'>
              <div className='flex gap10p sharingItem rainbow'>
                <img src={s3StaticPath('img/home/slicing/wtk/bg-16.png')} alt={"ReliveMemories"} />
                <div>
                  <h4 className='pollyBold'>Relive the Memories</h4>
                  <p>Cherish wedding memories for a lifetime with keepsake video and 20x30 PhotoMozaix print</p>
                  <img src={s3StaticPath('img/home/slicing/wtk/four.svg')} alt={'four'} className='sharingPos' />
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <section className='coupleSaying py-5r'>
          <h2 className='pollyBold mainHeading text-center mb2r'>What's Wedding Couple Says</h2>
          <div className='sliderSectionWrapper'>
            <div className='sliderSection'>
              <div className='sliderOne' onClick={this.testimonial1}>
                <div className={`${testimonial ? "testimonialOverlay" : ""}`}>
                  <img src={s3StaticPath('img/home/slicing/wtk/bg.png')} alt={'BG'} />
                </div>
                <div className={`testimonial ${testimonial && "active"}`}>
                  <h5>Sherri and Corey</h5>
                  <h3>Exceptional Services.</h3>
                  <p>Our resort gave us this incredible gift for booking our wedding guests with them. The app was so easy to use, even our grandparents shared their favorite photos! It was so nice for everyone to easily message each other, and to have the itinerary and rooming list at everyone fingertips.</p>
                </div>
              </div>
              <div className='sliderTwo' onClick={this.testimonial2}>
                <div className={`${testimonial2 ? "testimonialOverlay" : ""}`}>
                  <img src={s3StaticPath('img/home/slicing/wtk/bg-5.png')} alt={'BG1'} />
                </div>
                <div className={`testimonial ${testimonial2 && "active"}`}>
                  <h5>Sherri and Corey 22</h5>
                  <h3>Exceptional Services.</h3>
                  <p>Our resort gave us this incredible gift for booking our wedding guests with them. The app was so easy to use, even our grandparents shared their favorite photos! It was so nice for everyone to easily message each other, and to have the itinerary and rooming list at everyone fingertips.</p>
                </div>
              </div>
              <div className='sliderThree' onClick={this.testimonial3}>
                <div className={`${testimonial3 ? "testimonialOverlay" : ""}`}>
                  <img src={s3StaticPath('img/home/slicing/wtk/bg-6.png')} alt={'BG2'} />
                </div>
                <div className={`testimonial ${testimonial3 && "active"}`}>
                  <h5>Sherri and Corey 33</h5>
                  <h3>Exceptional Services.</h3>
                  <p>Our resort gave us this incredible gift for booking our wedding guests with them. The app was so easy to use, even our grandparents shared their favorite photos! It was so nice for everyone to easily message each other, and to have the itinerary and rooming list at everyone fingertips.</p>
                </div>
              </div>
              <div className='sliderFour' onClick={this.testimonial4}>
                <div className={`${testimonial4 ? "testimonialOverlay" : ""}`}>
                  <img src={s3StaticPath('img/home/slicing/wtk/bg-7.png')} alt={'BG3'} />
                </div>
                <div className={`testimonial ${testimonial4 && "active"}`}>
                  <h5>Sherri and Corey 44</h5>
                  <h3>Exceptional Services.</h3>
                  <p>Our resort gave us this incredible gift for booking our wedding guests with them. The app was so easy to use, even our grandparents shared their favorite photos! It was so nice for everyone to easily message each other, and to have the itinerary and rooming list at everyone fingertips.</p>
                </div>
              </div>
              <div className='sliderFive' onClick={this.testimonial5}>
                <div className={`${testimonial5 ? "testimonialOverlay" : ""}`}>
                  <img src={s3StaticPath('img/home/slicing/wtk/bg-8.png')} alt={'BG4'} />
                </div>
                <div className={`testimonial ${testimonial5 && "active"}`}>
                  <h5>Sherri and Corey 55</h5>
                  <h3>Exceptional Services.</h3>
                  <p>Our resort gave us this incredible gift for booking our wedding guests with them. The app was so easy to use, even our grandparents shared their favorite photos! It was so nice for everyone to easily message each other, and to have the itinerary and rooming list at everyone fingertips.</p>
                </div>
              </div>
              <div className='sliderSix' onClick={this.testimonial6}>
                <div className={`${testimonial6 ? "testimonialOverlay" : ""}`}>
                  <img src={s3StaticPath('img/home/slicing/wtk/bg-9.png')} alt={'BG5'} />
                </div>
                <div className={`testimonial ${testimonial6 && "active"}`}>
                  <h5>Sherri and Corey 66</h5>
                  <h3>Exceptional Services.</h3>
                  <p>Our resort gave us this incredible gift for booking our wedding guests with them. The app was so easy to use, even our grandparents shared their favorite photos! It was so nice for everyone to easily message each other, and to have the itinerary and rooming list at everyone fingertips.</p>
                </div>
              </div>
              <div className='sliderSeven' onClick={this.testimonial7}>
                <div className={`${testimonial7 ? "testimonialOverlay" : ""}`}>
                  <img src={s3StaticPath('img/home/slicing/wtk/bg-10.png')} alt={'BG6'} />
                </div>
                <div className={`testimonial ${testimonial7 && "active"}`}>
                  <h5>Sherri and Corey 77</h5>
                  <h3>Exceptional Services.</h3>
                  <p>Our resort gave us this incredible gift for booking our wedding guests with them. The app was so easy to use, even our grandparents shared their favorite photos! It was so nice for everyone to easily message each other, and to have the itinerary and rooming list at everyone fingertips.</p>
                </div>
              </div>
              <div className='sliderEight' onClick={this.testimonial8}>
                <div className={`${testimonial8 ? "testimonialOverlay" : ""}`}>
                  <img src={s3StaticPath('img/home/slicing/wtk/bg-11.png')} alt={"BG7"} />
                </div>
                <div className={`testimonial ${testimonial8 && "active"}`}>
                  <h5>Sherri and Corey 88</h5>
                  <h3>Exceptional Services.</h3>
                  <p>Our resort gave us this incredible gift for booking our wedding guests with them. The app was so easy to use, even our grandparents shared their favorite photos! It was so nice for everyone to easily message each other, and to have the itinerary and rooming list at everyone fingertips.</p>
                </div>
              </div>
              <div className='sliderNine' onClick={this.testimonial9}>
                <div className={`${testimonial9 ? "testimonialOverlay" : ""}`}>
                  <img src={s3StaticPath('img/home/slicing/wtk/bg-12.png')} alt={'BG8'} />
                </div>
                <div className={`testimonial ${testimonial9 && "active"}`}>
                  <h5>Sherri and Corey 99</h5>
                  <h3>Exceptional Services.</h3>
                  <p>Our resort gave us this incredible gift for booking our wedding guests with them. The app was so easy to use, even our grandparents shared their favorite photos! It was so nice for everyone to easily message each other, and to have the itinerary and rooming list at everyone fingertips.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Parallax
          // bgImage={AppDownload}
          // bgImageAlt={AppDownload}
          strength={500}
          className="securityParallax"
        >
          <section className='container'>
            <Row className='downloadSection'>
              <Col md={7}>
                <h2 className='pollyBold mainHeading mt0 mb1r'>Download for free today & let the fun begin.</h2>
                <div className='flex itemsCenter mb2r'>
                  <Link to="/">
                    <img src={s3StaticPath('img/home/slicing/wtk/appstore.png')} alt={'AppStore'} />
                  </Link>
                  <Link to="/">
                    <img src={s3StaticPath('img/home/slicing/wtk/android.png')} alt={'Android'} />
                  </Link>
                </div>
                <h4 className='pollyBold'>Check out this short video to learn more about the Wedding Trip Kit.</h4>
              </Col>
              <Col md={5}>
                <img src={s3StaticPath('img/home/slicing/wtk/app-download.png')} alt={'AppDownload'} />
              </Col>
            </Row>
          </section>
        </Parallax>

      </main>
    );
  }
}

export default GtvHome;
