import React, { Component } from 'react';
import FrontEndRoutes from "routes/FrontEndRoutes.jsx";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { IoMdMenu } from 'react-icons/io';
import { FiChevronDown } from 'react-icons/fi';
import { Helmet } from 'react-helmet';

import { login, logout, clearLoginError } from 'redux/actions/front-end-action';
import User from 'helper/User';
import Logo from 'components/FrontEnd/Brand/Logo';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import { Roles } from 'constants/Roles';
import SideNavDashboard from 'layouts/Frontend/Dashboard/SideNavDashboard';
import { adminUserActions } from 'redux/actions/admin-user-action';
import LoginPopUp from '../../ThemeComponents/LoginPopUp';
import { objectsConstants } from '../../../redux/constant/objects-constant';
import { Height } from '@mui/icons-material';
import loginIcon from "assets/wkt/login.svg"
// import MyImage from 'components/Upload/MyImage';

class HeaderPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      routes: '',
      showNav: false,
      email: '',
      password: '',
      submitted: false,
      showHelp: false,
      loginShow: false,
      itkUser: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    if (Object.keys(this.props.match.params).length > 1) {
      const params = { ...this.props.match.params };
      if (params.username !== undefined && params.password !== undefined) {
        this.props.loginCall(params.username, params.password)
      }
    }
  };

  handleLoginShow = () => {
    console.log("2.1351351.")
    this.setState({
      loginShow: !this.state.loginShow
    })
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    document.querySelector('.loginIcon').classList.add('open');
    this.setState({ submitted: true });
    const { email, password } = this.state;
    if (email && password) {
      this.props.loginCall(email, password);
    }
  }

  sortByColumn(a, colIndex) {
    a.sort(sortFunction);
    function sortFunction(a, b) {
      if (a[colIndex] === b[colIndex]) {
        return 0;
      }
      else {
        return (a[colIndex] < b[colIndex]) ? -1 : 1;
      }
    }
    return a;
  }

  handleNav = () => {
    this.setState({ showNav: !this.state.showNav })
  }

  logout = (e) => {
    e.preventDefault();
    this.props.dispatch(logout());
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.loginError !== prevProps.loginError) {
      if (this.props.loginError === 'You have entered invalid email or password, please try again') {
        this.setState({
          itkUser: true
        })
      }
    }
  }
  getChildState = (state) => {
    console.log("state", state);
    this.setState({
      itkUser: state
    }, () => {
      this.props.clearCall()
    })
  }

  render() {
    const injectGA = () => {
      if (typeof window == 'undefined') {
        return;
      }
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', 'UA-685268-14');
    }
    const { email, password, submitted } = this.state
    return (
      <React.Fragment>
        {
          this.state.itkUser &&
          <LoginPopUp
            title='Message'
            parentState={this.getChildState}
          />
        }
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-685268-14"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-685268-14');
            `}
          </script>
        </Helmet>
        <div className=''>
          <header id="header" test={this.props.history.location.pathname} className={this.props.history.location.pathname === '/Home' || this.props.history.location.pathname === '/' ? '' : 'no_slider'}>
            <nav className='navbar navbar-default mb0'>
              <div className="container">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
                    <span className="sr-only">Toggle navigation</span>
                    <i className="fa fa-navicon"></i>
                  </button>

                  <Link className="navbar-brand p0F" to="/">
                    {
                      (this.props.history.location.pathname !== '/Home' && this.props.history.location.pathname !== '/') &&
                      <div>
                        <Logo className="default test1" />
                        <Logo className="gray test2" />
                      </div>
                    }

                    {
                      (this.props.history.location.pathname === '/Home' || this.props.history.location.pathname === '/') &&
                      <Logo className="default frontEndLogo" />
                    }
                  </Link>
                </div>


                <div className="collapse navbar-collapse navbar-ex1-collapse wktNav">
                  <ul className="nav navbar-nav navbar-right mainNav">
                    <li><Link to={"/"}>Home</Link></li>
                    <li><Link to={"/how-it-works"}> How It Works </Link></li>
                    {/* <li><Link to={"/"}>For Resorts</Link></li> */}
                    <li>
                      <a href="#" className="dropdown-toggle text_14" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Product & Services <FiChevronDown style={{ verticalAlign: "middle" }} /> </a>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to={"/Keepsake_Video"} className="text_14">
                            Keep Sake Video
                          </Link>
                        </li>
                        <li>
                          <Link to={"/Mozaix"} className="text_14">
                            PhotoMozaix™
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li><Link to={"/About_Us"}>About</Link></li>
                    {
                      User.isAuthenticated() ? "" :
                        <li className='loginIcon'>
                          <a
                            href="#"
                            className="dropdown-toggle text_14 loginBtn1"
                            style={{ position: 'relative' }}
                            onClick={this.handleLoginShow}
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          > Login
                          </a>

                          {
                            this.state.loginShow &&
                            <ul className="loginForm dropdown-menu loginPopup loginDropdownMenu">
                              <a
                                style={{ position: "absolute", top: "5px", right: "5px", color: "red" }}
                                href="#"
                                onClick={() => { this.setState({ loginShow: !this.state.loginShow }) }}
                                type="button"
                              // aria-haspopup="true"
                              // aria-expanded="false"
                              >X
                              </a>
                              <li><input type="text" name="email" value={email} onChange={this.handleChange} className={"form-control" + (submitted && !email ? ' has-error' : '')} placeholder="Username" /></li>
                              {submitted && !email &&
                                <li style={{ marginTop: '0' }} className="help-block">Username is required.</li>
                              }
                              <li className='password'><input onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  this.button.click();
                                }
                              }} type="password" value={password} onChange={this.handleChange} className={"form-control" + (submitted && !password ? ' has-error' : '')} name='password' placeholder="Password" /></li>
                              {(submitted && !password) &&
                                <li style={{ marginTop: '0' }} className="help-block">Password is required.</li>
                              }
                              <li style={{ display: 'flex' }}>
                                <button ref={button => this.button = button} onClick={this.handleSubmit} className="btn medium_blue_gradient btn_login">Login</button>
                                <button className="btn red_gradient btn_login" onClick={() => { this.setState({ showHelp: !this.state.showHelp }) }}>Help</button>
                              </li>
                              <li><Link to="/forgot-password" className="forgotPass text-center anchorHover">Forgot Password</Link></li>
                            </ul>
                          }
                        </li>
                    }

                    {
                      User.hasRole(Object.values(Roles)) &&
                      <li onClick={this.handleNav}>
                        <a href="#/" style={{ fontSize: "30px", cursor: 'pointer' }}><IoMdMenu /></a>
                      </li>
                    }
                  </ul>
                  <ul className="nav navbar-nav navbar-right" style={{ display: 'none' }}>
                    {
                      FrontEndRoutes.map((prop, key) => {
                        if (!prop.redirect && prop.showMenu) {
                          if (User.isAuthenticated() && prop.name === 'Login') {
                            return ('');
                          }
                          else if (!User.isAuthenticated() && prop.path === '/dashboard') {
                            return ('');
                          }
                          else if (User.isAuthenticated() && prop.name === "Sign Up") {
                            return '';
                          }
                          else if (prop.name === 'Logout') {
                            if (User.isAuthenticated() && !User.hasRole(Object.values(Roles).filter(item => item !== 'admin'))) {
                              return <li key={key}>
                                <Link onClick={(e) => { e.preventDefault(); this.props.adminLogout() }} to={prop.path.replace(/(\/:[\w]*\??)+/, '')} className="text_14" data-scroll="main_slider">
                                  {prop.name}
                                </Link>
                              </li>
                            } else {
                              return ''
                            }
                          }
                          else {
                            return (
                              prop.childNav ?
                                <li key={key} className={prop.navClass ? prop.navClass : " dropdown"}>
                                  <a href="#/" className="dropdown-toggle text_14" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{prop.name} <FiChevronDown style={{ verticalAlign: "middle" }} /></a>
                                  <ul className="dropdown-menu">
                                    {
                                      prop.childNav.map((item, index) =>
                                        <li key={index}>
                                          <Link to={item.path.replace(/(\/:[\w]*\??)+/, '')} className="text_14">
                                            {item.name}
                                          </Link>
                                        </li>
                                      )
                                    }
                                  </ul>
                                </li>
                                :
                                prop.name === 'Login' ? '' :
                                  <li key={key} className={prop.navClass ? prop.navClass : ""}>
                                    <Link to={prop.path.replace(/(\/:[\w]*\??)+/, '')} className="text_14" data-scroll="main_slider">
                                      {prop.name}
                                    </Link>
                                  </li>
                            );
                          }
                        }
                        return null;
                      })
                    }
                    {
                      User.isAuthenticated() ? "" :
                        <li className='loginIcon'>
                          <a
                            href="#"
                            className="dropdown-toggle text_14"
                            style={{ position: 'relative' }}
                            onClick={this.handleLoginShow}
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >Login
                          </a>

                          {
                            this.state.loginShow &&
                            <ul className="loginForm dropdown-menu loginPopup loginDropdownMenu">
                              <a
                                style={{ position: "absolute", top: "5px", right: "5px", color: "red" }}
                                href="#"
                                onClick={() => { this.setState({ loginShow: !this.state.loginShow }) }}
                                type="button"
                              // aria-haspopup="true"
                              // aria-expanded="false"
                              >X
                              </a>
                              <li><input type="text" name="email" value={email} onChange={this.handleChange} className={"form-control" + (submitted && !email ? ' has-error' : '')} placeholder="Username" /></li>
                              {submitted && !email &&
                                <li style={{ marginTop: '0' }} className="help-block">Username is required.</li>
                              }
                              <li className='password'><input onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  this.button.click();
                                }
                              }} type="password" value={password} onChange={this.handleChange} className={"form-control" + (submitted && !password ? ' has-error' : '')} name='password' placeholder="Password" /></li>
                              {(submitted && !password) &&
                                <li style={{ marginTop: '0' }} className="help-block">Password is required.</li>
                              }
                              <li style={{ display: 'flex' }}>
                                <button ref={button => this.button = button} onClick={this.handleSubmit} className="btn purple_gradient btn_login">Login</button>
                                <button className="btn red_gradient btn_login" onClick={() => { this.setState({ showHelp: !this.state.showHelp }) }}>Help</button>
                              </li>
                              <li><Link to="/forgot-password" className="forgotPass text-center">Forgot Password</Link></li>
                            </ul>
                          }
                        </li>
                    }

                    {
                      User.hasRole(Object.values(Roles)) &&
                      <li onClick={this.handleNav}>
                        <a href="#/" style={{ fontSize: "30px", cursor: 'pointer' }}><IoMdMenu /></a>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </nav>
            <SideNavDashboard handleNav={this.handleNav} title="Login Help" showNav={this.state.showNav} />
            <ThemeModal show={this.state.showHelp} title="Help" hide={() => { this.setState({ showHelp: !this.state.showHelp }) }}>
              <div className="text_22">Travelers & Group Leaders</div>
              <p className="text_14">If you do not know your login, please look on your traveler app card or watch the DVD video. Your group's username & password appears at the beginning and end of the your video. If you have lost the app card and video, please <Link onClick={() => { this.setState({ showHelp: !this.state.showHelp }) }} to="/Contact_Us" className="linkColor">Contact Us</Link> to get your login.</p>

              <div className="text_22 mt-lg">Tour Operators</div>
              <p className="text_14">If you do not know your login, click this <Link onClick={() => { this.setState({ showHelp: !this.state.showHelp }) }} to="/forgot-password" className="linkColor">Forgot Password</Link> link or <Link onClick={() => { this.setState({ showHelp: !this.state.showHelp }) }} to="/Contact_Us" className="linkColor">Contact Us.</Link></p>
            </ThemeModal>
          </header>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginCall: (email, password) => { dispatch(login(email, password)) },
    logoutCall: () => { dispatch(logout()) },
    clearCall: () => { dispatch(clearLoginError()) },
    adminLogout: () => { dispatch(logout()) }
  }
}

function mapStateToProps(state) {
  const { loading, User, loggedIn, loginError } = state.frontEndUserReducer;
  return {
    loading, User, loggedIn, loginError
  };
}
const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderPage);
export default Header;
