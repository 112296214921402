import React, { Component } from 'react';
import { connect } from 'react-redux'
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialForm from "./TestimonialForm";
import User from '../../../helper/User';
import { getTestimonials } from '../../../redux/actions/testimonials';
const moment = require('moment');

class CustomerTestimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }

  componentDidMount() {
    this.props.getTestimonials(User.brand('id'), '', '', '');
  }

  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }
  render() {
    const { testimonails } = this.props;
    const breaks = <React.Fragment>
      <br /><br /><br />
    </React.Fragment>;
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    // let sliderSettings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   accessibility: false,
    //   className: 'benefits',
    //   prevArrow: <div><MdArrowBack /></div>,
    //   nextArrow: <div><MdArrowForward /></div>,
    // };

    return (
      <React.Fragment>

        <section id="contactUs" className="section bg_alter">
          <div className="container">
            <div className="row">
              <TestimonialForm />
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container mt-xlg">
            <div className="row mb-xlg">
              <div className="col-md-12 mb-xlg"><h2 className="panel_heading">Customer Testimonials</h2></div>
            </div>
            {
              testimonails.data.map(testimonail => {
                return (
                  <div key={testimonail.id} className="row testimonialsTimeline">
                    <div className="col-md-6">
                      <div className="text_16">
                        {testimonail.description}
                        {testimonail.description.length < 1000 ? breaks : ''}
                        <div></div>
                        <div className="testiAuthor">
                          <div className="name">{testimonail.author}</div>
                          <div className="company">{testimonail.company}</div>
                          <div className="date">{moment(testimonail.created_at).format('MMM DD YYYY')}</div>
                        </div>
                      </div>

                      <div className="speak-bubble"><span>❝</span></div>
                    </div>
                  </div>
                )
              })
            }

            {/* <div className="row testimonialsTimeline">
              <div className="col-md-6">
                <div className="text_16">
                  Our orchestra traveled from Tulsa, OK to Nashville, TN, and the app made communication between myself, my chaperones, and my students super easy. Everyone really "bought in" to uploading photos from the trip, so our video is over 20 minutes of memories that will truly last forever. I am especially impressed with the final product - the polished and high-quality video that the Wedding TripKit team put together. Thank you so much!
                            <div></div>
                  <div className="testiAuthor">
                    <div className="name">Liza Villarreal</div>
                    <div className="company">Edison Preparatory Orchestra</div>
                    <div className="date">Apr 23 2019</div>
                  </div>
                </div>

                <div className="speak-bubble"><span>❝</span></div>
              </div>
            </div>

            <div className="row testimonialsTimeline">
              <div className="col-md-6">
                <div className="text_16">
                  Both the band and chorus recently took a trip to Orlando. As directors, we loved the app. It has been so much fun getting to see the awesome experience our students had on our trip. We look forward to having the keepsake DVD that we can use to show to others in the future. Thanks so much!
                            <div></div>
                  <div className="testiAuthor">
                    <div className="name">Jason R.</div>
                    <div className="company">Bob Rogers Travel</div>
                    <div className="date">Apr 5 2019</div>
                  </div>
                </div>

                <div className="speak-bubble"><span>❝</span></div>
              </div>
            </div>

            <div className="row testimonialsTimeline">
              <div className="col-md-6">
                <div className="text_16">
                  This is the third time that my school group has used Wedding TripKit and I am always amazed by the finished product. This company does an astonishing job at combining our photos and videos to make a one of a kind souvenir. This DVD is a great way for them to share their photos too. It saves a lot of time since not everyone needs to stop at take a picture of the same thing. It also allows parents at home to see the pictures real time. This video is also a great recruiting tool, getting the younger grades excited about Spanish! I can't imagine taking a student trip and not using Wedding TripKit in the future!
                            <div></div>
                  <div className="testiAuthor">
                    <div className="name">Chris B.</div>
                    <div className="company">Appleseed Expeditions</div>
                    <div className="date">Mar 22 2019</div>
                  </div>
                </div>

                <div className="speak-bubble"><span>❝</span></div>
              </div>
            </div> */}
          </div>
        </section>
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => {
  const { testimonails, pages, perPage, current_page } = state.testimonialReducer;
  return {
    testimonails, pages, perPage, current_page
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTestimonials: (brand_id, pageSize, sort, filter) => { dispatch(getTestimonials(brand_id, pageSize, sort, filter)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTestimonials)