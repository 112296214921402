import React, { Component } from 'react';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
// import MyImage from 'components/Upload/MyImage';
import SlickSlider from "react-slick";
import MainSlider from "components/FrontEnd/Header/Slider";
import TestiCarousel from 'components/FrontEnd/Testimonials/TestimonialsSlider';
import { s3StaticPath } from 'helper/helperFunctions';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    // this.setState(prevState => ({
    //     isHovered: !prevState.isHovered
    // }));

    $("#clients div.client").hover(function () {
      $("#clients div.client").removeClass("pink_gradient")
      $(this).addClass("pink_gradient")
    }, function () {
      $("#clients div.client").removeClass("pink_gradient")
    })
  }

  render() {
    // const hoverClass = this.state.isHovered ? "client pink_gradient" : "client";
    const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
      <div {...props}>{children}</div>
    );

    let sliderSettings = {
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: false,
      className: 'benefits',
      prevArrow: <SlickButtonFix><MdArrowBack /></SlickButtonFix>,
      nextArrow: <SlickButtonFix><MdArrowForward /></SlickButtonFix>,
    };

    return (
      <React.Fragment>
        <MainSlider />
        <section id="uses_of_gtv" className="section bg_alter">
          <h2 className="panel_heading">Uses of GTV App</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-3 mt-20">
                <div className="useBox text-center">
                  <img src={s3StaticPath('img/tour_operator_icon.png')} alt="Tour Operators" />

                  <div className="boxHeading">Tour Operators</div>

                  <p>Each traveler gets a storytelling DVD made from their trip photos. Your branding is featured in all aspects of the Wedding TripKit&trade; program...</p>

                  {
                    /*
                    <div>
                      <a href="" className="view_more">View More</a>
                    </div>

                    */
                  }
                </div>
              </div>

              <div className="col-md-3 mt-20">
                <div className="useBox text-center">
                  <img src={s3StaticPath('img/leaders_icon.png')} alt="Leaders &amp; Educators" />

                  <div className="boxHeading">Leaders &amp; Educators</div>

                  <p>
                    Your group shares their trip photos in one place using the FREE app &amp; website. Each traveler gets a card with your group's unique login. Group Leaders get a...
                  </p>
                  {
                    /*
                  <div>
                    <a href="" className="view_more">View More</a>
                  </div>
                  */
                  }
                </div>
              </div>

              <div className="col-md-3 mt-20">
                <div className="useBox text-center">
                  <img src={s3StaticPath('img/students_icon.png')} alt="Students &amp; Travelers" />

                  <div className="boxHeading">Students &amp; Travelers</div>

                  <p>
                    Share your trip photos in one place using the FREE app &amp; website. Then share to social media from the app or website. After the trip, we make a storytelling DVD...
                  </p>
                  {
                    /*
                  <div>
                    <a href="" className="view_more">View More</a>
                  </div>
                   */
                  }
                </div>
              </div>

              <div className="col-md-3 mt-20">
                <div className="useBox text-center">
                  <img src={s3StaticPath('img/staying_icon.png')} alt="Staying at Home" />

                  <div className="boxHeading">Staying at Home</div>

                  <p>
                    Travel along as the once in a lifetime experience unfolds! Simply login to the FREE app or website with the group's login. Each traveler will have received a card with their...
                  </p>
                  {
                    /*
                  <div>
                    <a href="" className="view_more">View More</a>
                  </div>
                  */
                  }
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="since" className="section bg_alter">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1 text-center">
                <div className="since_icon">
                  <img src={s3StaticPath('img/since_icon.png')} alt="Since 1999" />
                </div>

                <p className="since_text">
                  Since 1999, millions of travelers have received our storytelling keepsake videos. They are fun to watch after the trip, and even more fun to watch 10 years later. When watched, travelers are reminded of the tour company who put it all together, YOU!
                </p>

                <p className="since_text smaller">
                  Each traveler receives a keepsake DVD video which features your company logo. The Wedding TripKit™ program creates engagement with your group leaders and travelers. Throughout the program, they are always reminded of your company. Click here to check out all the places your company logo will appear.
                </p>

                <div className="sinceBtns text-center">
                  <a href="#/" className="gradient_btn themeBtn_14 purple_gradient hover_dark">Login to Place Order</a>
                  <a href="#/" className="gradient_btn themeBtn_14 red_gradient hover_dark">Contact Us To Get Login</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="benefits" className="section bg_alter">
          <div className="slider_container blue_gradient">
            <div className="container flexElem respFlex">
              <div className="flexCol text_left_center_991">
                <div className="smaller_heading">
                  Benefits Of GTV
                </div>
                <div className="bigger_heading">
                  Custom Branded<br />App Option
                </div>
                <div>
                  <a href="#/" className="benefitsBtn hover_dark">Place Your Order Here</a>
                </div>
              </div>

              <div className="flexCol">
                <div className="side_slider">
                  <SlickSlider {...sliderSettings}>
                    <div><img src={s3StaticPath('img/slider_placeholder.jpg')} alt="" /></div>
                    <div><img src={s3StaticPath('img/slider_placeholder.jpg')} alt="" /></div>
                    <div><img src={s3StaticPath('img/slider_placeholder.jpg')} alt="" /></div>
                    <div><img src={s3StaticPath('img/slider_placeholder.jpg')} alt="" /></div>
                  </SlickSlider>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="section bg_alter">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="feature_heading section_heading">
                  GROUP LEADERS &amp; EDUCATORS
                </div>
                <p className="text_16">The memories created on your trip are worth keeping. We make a storytelling DVD video made from photos taken on your trip and we include background music and custom titles. Travelers share their photos with our FREE Wedding TripKit app or through this website. This requires very little effort on your part. With today's smartphones, everyone takes pictures.</p>
              </div>
            </div>

            <div className="row features_container">
              <div className="col-md-3">
                <div className="feature_box">
                  <p className="text_16">We Provide you with a username &amp; password to login to our app and website to upload trip photos. or we can send you a digital camera or memory card to use.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="feature_box">
                  <p className="text_16">Your group upload photos with our app or website while you travel and or after you travel. you return any of our equipment back in the prepaid mailer we provide.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="feature_box">
                  <p className="text_16">We edit your photos and re-post them to the app and website. we create your video from the edited photos. your photos can only be accessed with your group’s username &amp; password.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="feature_box">
                  <p className="text_16">We ship your DVDs to you and male your video electronically available through our apps &amp; website</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="video" className="section bg_alter">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-12">
                <div className="section_heading">
                  Watch this short video to learn how the Wedding TripKit&trade; App works!!
                </div>
              </div>

              <div className="col-md-8 col-md-offset-2">
                <div className="video_placeholder">
                  <img src={s3StaticPath('img/video_placeholder.jpg')} alt="Video placeholder" />

                  <div className="video_play blue_gradient hover_dark"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="students" className="section bg_alter">
          <div className="container">
            <div className="section_icon">
              <img src={s3StaticPath('img/students_travel.png')} alt="STUDENTS &amp; TRAVELERS" />
            </div>
            <div className="student_heading section_heading">
              STUDENTS &amp; TRAVELERS
            </div>

            <div className="student_text">
              <p className="text_16">
                So you are going on a trip! You are about to learn and see things you may have only read about. Experiencing it in real life, is something you will want to remember and relive for years to come. Take lots of pictures! These will help you tell the story of your trip.
              </p>

              <p className="text_16">
                Wedding TripKit&trade; makes it easy for you and your fellow travelers to share photos with each other. Use our FREE app, enter in the unique login for your group, and share your favorite photos with everyone traveling with you. If you are using a regular digital camera, use the same login to share your pics on this website.
              </p>

              <a href="#/" className="themeBtn_12 view_btn blue_gradient hover_dark">View More</a>
            </div>
          </div>
        </section>

        <div className="sec_line"></div>

        <section id="family" className="section bg_alter">
          <div className="container">
            <div className="section_icon">
              <img src={s3StaticPath('img/family_staying.png')} alt="FAMILY STAYING AT HOME" />
            </div>
            <div className="family_heading section_heading">
              FAMILY STAYING AT HOME
            </div>

            <div className="family_text">
              <p className="text_16">
                We are excited about producing a keepsake DVD for your student made from everyone's shared pictures taken during their group trip!
              </p>

              <p className="text_16">
                Your tour operator is providing this keepsake as part of your student's tour package.
              </p>

              <p className="text_16">There is no extra cost!</p>
              <p className="text_16">
                As a parent, you know more now than ever the importance of the memories recorded in pictures and videos. When you look...
              </p>

              <a href="#/" className="themeBtn_12 view_btn purple_gradient hover_dark">View More</a>
            </div>
          </div>
        </section>

        <div className="sec_line"></div>

        <section id="the_journal" className="section bg_alter">
          <h2 className="panel_heading">The Journal</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-3 mt-20">
                <div className="useBox">
                  <img src={s3StaticPath('img/packing_1.png')} alt="Packing List" />

                  <div className="boxHeading">Packing List</div>

                  <p>
                    Each traveler gets a storytelling DVD made from their trip photos. Your branding is featured in all aspects of the Wedding TripKit&trade; program...
                  </p>

                  <div>
                    <a href="#/" className="themeBtn_12 blue_gradient hover_dark">View More</a>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mt-20">
                <div className="useBox">
                  <img src={s3StaticPath('img/packing_2.png')} alt="Packing List" />


                  <div className="boxHeading">Packing List</div>

                  <p>
                    Your group shares their trip photos in one place using the FREE app &amp; website. Each traveler gets a card with your group's unique login. Group Leaders get a...
                  </p>

                  <div>
                    <a href="#/" className="themeBtn_12 blue_gradient hover_dark">View More</a>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mt-20">
                <div className="useBox">
                  <img src={s3StaticPath('img/packing_3.png')} alt="Packing List" />


                  <div className="boxHeading">Packing List</div>

                  <p>
                    Share your trip photos in one place using the FREE app &amp; website. Then share to social media from the app or website. After the trip, we make a storytelling DVD...
                  </p>

                  <div>
                    <a href="#/" className="themeBtn_12 blue_gradient hover_dark">View More</a>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mt-20">
                <div className="useBox">
                  <img src={s3StaticPath('img/packing_4.png')} alt="Packing List" />


                  <div className="boxHeading">Packing List</div>

                  <p>
                    Travel along as the once in a lifetime experience unfolds! Simply login to the FREE app or website with the group's login. Each traveler will have received a card with their...
                  </p>

                  <div>
                    <a href="#/" className="themeBtn_12 blue_gradient hover_dark">View More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonial" className="section green_gradient">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <div className="testi_slider">
                  <TestiCarousel />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="section bg_alter">
          <h2 className="panel_heading">Our Clients</h2>

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="clients">
                  <div className="client" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                    <img src={s3StaticPath('img/client_icon.jpg')} alt="Client name" />
                  </div>
                  <div className="client" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                    <img src={s3StaticPath('img/client_icon.jpg')} alt="Client name" />
                  </div>
                  <div className="client" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                    <img src={s3StaticPath('img/client_icon.jpg')} alt="Client name" />
                  </div>
                  <div className="client" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                    <img src={s3StaticPath('img/client_icon.jpg')} alt="Client name" />
                  </div>
                  <div className="client" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                    <img src={s3StaticPath('img/client_icon.jpg')} alt="Client name" />
                  </div>
                  <div className="client" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                    <img src={s3StaticPath('img/client_icon.jpg')} alt="Client name" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Home;