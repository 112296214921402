import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Card from "components/Card/Card";
import { getRequiredAssets } from "redux/actions/order-actions";
import PrintOrderLabel from "components/Admin/Order/PrintOrderLabel";
import { baseUrl } from "helper/ApiCall";

class ProcessOrder extends React.Component {

    constructor(props) {
        super(props);
        this.props.getRequiredAssetsCall({ orderIds: [...this.props.orders] });
    }

    printOrderLabels() {

    }

    render() {

        const {
            required_assets,
            // cam_count
        } = this.props.requiredAssets;
        return (
            <Grid fluid>
                <Card bsClass={["innerCard"]} content={
                    <Row>
                        <Col md={6}>
                            <p>You have Chosen to process {this.props.orders.length} orders</p>
                            <div className='my-20' >
                            <a rel="noopener noreferrer" className="backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default" target="_blank" href={`${baseUrl.slice(0,-1)}/generateTitleSheets/${this.props.orders}?status=1&slug=camera`} >
                                Generate Title Sheets
                            </a>
                            </div>
                            {/* <PrintOrderLabel base_url={baseUrl.slice(0,-1)} className="backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default" order_page={false} text='Generate Order Labels' orders={this.props.orders} />
                            <p>
                                <small className='text-muted' >Note: Will not generate for Upload only</small>

                            </p> */}
                            <div className='my-20' >
                                <a rel="noopener noreferrer" className="backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default" target="_blank" href={`${baseUrl.slice(0,-1)}/generateAppCardAllNoQR/${this.props.orders}`} >
                                    Generate App Cards
                                </a>
                            </div>
                                {/*<GenerateAppCard className="backButton my-20 pt-sm no_radius pb-sm primary text-xs btn btn-lg btn-info btn btn-lg btn-info  btn btn-default"  base_url={baseUrl.slice(0,-1)} text='Generate App Travelers Cards' orders={this.props.orders} />*/}
                        </Col>
                        <Col md={6}>
                            <table className='table table-striped-custom'>
                                <thead style={{ background: '#000' }} >
                                    <tr>
                                        <th style={{ textAlign: 'center', color: '#fff', fontWeight: 600 }} colSpan='2' >Program type</th>
                                    </tr>
                                </thead>
                                <tbody style={{ background: '#fff' }} >
                                    {
                                        Object.keys(required_assets).map(item => {
                                            return (
                                                required_assets[item] > 0 ?
                                                <tr key={item} >
                                                    <td>
                                                        {item}
                                                    </td>
                                                    <td>
                                                        {required_assets[item]}
                                                    </td>
                                                </tr>
                                                : null
                                            );
                                        })
                                    }
                                    {
                                        /**
                                        <tr>
                                            <th style={{ textAlign: 'center', color: '#fff', fontWeight: 600, background: '#000' }} colSpan='2' ></th>
                                        </tr>
                                        {
                                            Object.keys(cam_count).map(item => {
                                                return (
                                                    <tr key={item} >
                                                        <td>{item}</td>
                                                        <td>{cam_count[item]}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        */
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                } />
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    const { requiredAssets } = state.ordersReducer;
    console.log('requiredAssets', requiredAssets);
    return { requiredAssets }
}

const mapDispatchToProps = dispatch => {
    return {
        getRequiredAssetsCall: (orders) => dispatch(getRequiredAssets(orders))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrder);
