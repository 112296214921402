import React, { Component } from 'react'
import { connect } from 'react-redux';
import { allOrderImagesByPhase, orderImages } from 'redux/actions/order-actions';
import $ from 'jquery';
import { SortableContainer, arrayMove, SortableElement } from 'react-sortable-hoc';
import ImagesAi from './ImagesAi';

const SortableImage = SortableElement(({ image, onClick, onSortOrder, item_index, imageSize }) => {
  return (
    // <>
    <ImagesAi image={image} imageSize={imageSize} />
    // <button onClick={() => onSortOrder(image)}>{'Sort Order_'+item_index}</button>
  )
});

const SortableImageGallery = SortableContainer(({ images, onClick, onSortOrder, imageSize }) => {
  return (
    <div>
      {images.map((image, index) => (
        <SortableImage
          key={index}
          item_index={index}
          image={image}
          onClick={onClick}
          onSortOrder={onSortOrder}
          imageSize={imageSize}
        />
      ))}
    </div>
  );
});

class PhotoGalleryAi extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imagesData: this.props.imagesData,
      // images: this.props.allImagesByPhase.images,
      pageSize: '',
      sizes: ['thumb', 'thumb_mid', 'thumb_large', 'thumb_xlarge', 'thumb_xxlarge', 'thumb_xxxlarge', 'full'],
      fullIndex: 6,
      imageSize: 3,
    }
    // this.props.allOrderImagesByPhaseCall(30000340, 'original', 'original');
    this.props.orderImagesCall(30000340, 'original', 'Uploaded-images', 1, '', '', this.state.pageSize, 'admin');
  }
  componentDidUpdate(prevProps) {
    if (this.props.imagesData !== prevProps.imagesData) {
      this.setState({
        imagesData: this.props.imagesData,
      });
    }
    // if (this.props.allImagesByPhase != prevProps.allImagesByPhase ) {
    // this.setState({images: this.props.allImagesByPhase.images});
    // } 
  }
  componentDidMount() {
    // this.props.allOrderImagesByPhaseCall(30000340, 'original', 'original');
    // this.props.allOrderImagesByPhaseCall(30000340, 'working', 'Editor');
  }
  handleImageClick = (image) => {
    // Handle image click here
  };

  handleSortOrder = (image) => {
    // Handle sort order button click here
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    console.log("onSortEnd", oldIndex, newIndex);
    if (oldIndex !== newIndex) {
      let imagesData = this.state.imagesData

      imagesData[oldIndex] = { ...imagesData[oldIndex], moved: 1 };
      imagesData = arrayMove(imagesData, oldIndex, newIndex);

      this.setState({
        imagesData: imagesData,
        // saveAlert: true
      });
    }
  }

  sortStart = ({ index }) => {
    console.log("Sort start", index);
    let sizes = this.state.sizes;
    $('.moving').find('.uploadedImageSize').find('img').after(() => {
      // return (this.state.selectedImages.map(item => {
      //     console.log("item: " , item);
      //     if (item !== index){
      //         console.log("item !== ", item);
      //         return `<img class="appendedImgs" src=${this.state.imagesData[item].resized_image_path.replace('SIZE', sizes[this.state.imageSize - 1])} />`
      //     }
      //     else
      //         return null
      // }) + (this.state.selectedImages.length > 0 ? `<span class="selectedCount purple_blue_gradient text_18">${this.state.selectedImages.length}</span>` : ''))
    })
    return null;
  }

  render() {
    console.log("================================", this.props.imagesData);
    const { imagesData } = this.state;
    let sizes = this.state.sizes;

    return (
      <div className='photogallery1111'>
        <div className={`companyImagesGrid hasMargin grid_${this.state.imageSize - 1}`}>
          <SortableImageGallery
            images={imagesData}
            onClick={this.handleImageClick}
            onSortOrder={this.handleSortOrder}
            onSortEnd={this.onSortEnd}
            imageSize={sizes[this.state.imageSize - 1]}
            onSortStart={this.sortStart}
            helperClass='moving'
            // axis='xy' 
            distance={5}
            transitionDuration={400} lockToContainerEdges={true}
          // useDragHandle
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { allImagesByPhase, imagesData } = state.ordersReducer;

  return {
    allImagesByPhase, imagesData
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    allOrderImagesByPhaseCall: (orderId, version_type, tabName) => { dispatch(allOrderImagesByPhase(orderId, version_type, tabName)) },
    orderImagesCall: (orderId, version_type, tabName, page, filter, sort, pageSize, userRole) => {
      dispatch(orderImages(orderId, version_type, tabName, page, filter, sort, pageSize, userRole))
    },
  });
};

// const PhotoGalleryAi = connect(mapStateToProps, mapDispatchToProps)(PhotoGalleryAi);
export default connect(mapStateToProps, mapDispatchToProps)(PhotoGalleryAi);

