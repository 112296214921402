import React, {Component} from 'react';
import $ from 'jquery';
import {Link} from 'react-router-dom';
import {s3StaticPath} from '../../helper/helperFunctions';
// import { Parallax, Background } from 'react-parallax';

import {FaCaretRight} from 'react-icons/fa';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Faqlist = ({quest, ans}) => {
    return (
        <div className="faqList">
            <div className="quest">
                <div className="iconArrow">
                    <FaCaretRight/>
                </div>
                <div>
                    {quest}
                </div>
            </div>
            <div className="ans" dangerouslySetInnerHTML={{__html: ans}}>
            </div>
        </div>
    )
}

class HowWorks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
        };
    }

    componentDidMount() {
        $(".faqList > .quest").click(function () {
            $(this).toggleClass("open");
            $(this).next(".ans").stop(true, true).slideToggle("fast");
        });

        $(window).resize(function () {
            setTimeout(function () {
                $("#how_it_works .banner").height($("#how_it_works .banner img").height());
            }, 300);
        });

        // var count = 10;
        $(window).scroll(function () {
            let a = $(this).scrollTop();
            a = a - (a / 5);
            $("#how_it_works .banner img").css({position: 'relative', top: a + 'px'});
        });
    }

    render() {
        const brands = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act'];
        const brand = brands.includes(document.domain.split('.')[0]) ? document.domain.split('.')[0] : 'gtv';
        const demoVideo = {
            'gtv': "https://grouptravelvideos.com/videos/GTV_App_Demo.mp4",
            'brt': "https://grouptravelvideos.com/videos/BRT_App_Demo_2021.mp4",
            'eti': "https://grouptravelvideos.com/videos/ETI_App_Demo_2020.mp4",
            'act': "https://grouptravelvideos.com/videos/GTV_App_Demo.mp4",
            'mci': 'https://grouptravelvideos.com/videos/MCI_App_Demo.mp4',
            'itk': "https://grouptravelvideos.com/videos/ITK_App_Demo.mp4"
        };
        return (
            <main id="how_it_works">
                <section className="banner">
                    <img alt="Banner header" src={s3StaticPath('img/works/wtk_photo_header.jpg')} />
                </section>

                <section className="steps">
                    <h1 className="wordpressHeading text-center pollyBold">How It Works</h1>
                    <div className="wordpressHeading subHeading text-center pollyBold">Getting Started Is Fast &amp; Easy</div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="flexElem alignCenter">
                                    <div className="text_left_center_991">
                                        <img src={s3StaticPath('img/works/step_1.png')} alt="Easy App Login"/>
                                    </div>
                                    <div className="text-left">
                                        <div className="step_heading">Easy App Login</div>
                                        <div className="step_details pollyRegular">
                                            We provide your group with a Unique username &amp; password to login to our app and
                                            website to upload trip photos and use the other app features.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="flexElem alignCenter">
                                    <div className="text_left_center_991">
                                        <img src={s3StaticPath('img/works/step_2.png')} alt="Upload Travel Pictures"/>
                                    </div>
                                    <div className="text-left">
                                        <div className="step_heading">Upload Travel Pictures</div>
                                        <div className="step_details pollyRegular">
                                            Your group will take pictures and add them into the private shared gallery with the
                                            rest of your groups’ photos.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="flexElem alignCenter">
                                    <div className="text_left_center_991">
                                        <img src={s3StaticPath('img/works/step_3.png')} alt="Sit Back &amp; Relax"/>
                                    </div>
                                    <div className="text-left">
                                        <div className="step_heading">Sit Back &amp; Relax</div>
                                        <div className="step_details pollyRegular">
                                            Our editors include music, transitions, &amp; effects while removing any
                                            duplicated or unwanted photos.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="flexElem alignCenter">
                                    <div className="text_left_center_991">
                                        <img src={s3StaticPath('img/works/step_4.png')}
                                             alt="Relive Your Travel Experience"/>
                                    </div>
                                    <div className="text-left">
                                        <div className="step_heading">Relive Your Travel Experience</div>
                                        <div className="step_details pollyRegular">
                                            Your group’s photos can only be accessed with your group’s
                                            username &amp; password along with your streaming video on the app and
                                            website.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="youtube">
                    <div className="container">
                        <div className="row flexElem flexResponsive alignCenter justifyCenter">
                            <div className="col-md-6 mt-lg">
                                <div className="wordpressHeading pollyBold">
                                    Safety. Communication. Memories.
                                </div>

                                <p>
                                    From better communication, traveler safety, and preserving the memories of your once
                                    in a lifetime experience, the Wedding TripKit App has everything you need to
                                    make the most out of your tour.
                                </p>
                            </div>

                            <div className="col-md-6 mt-lg">
                                <div className="contentContainer">
                                    <div className="video_placeholder mb-none">
                                        {/* <img src={s3StaticPath('img/video_placeholder.jpg')} className="block" alt="Video placeholder" /> */}
                                        <video onPlay={() => {
                                            $(".video_placeholder .video_play").hide()
                                        }} onPause={() => {
                                            $(".video_placeholder .video_play").show()
                                        }} id="demoVideo" controls src={demoVideo[brand]}
                                               type="video/mp4" poster={s3StaticPath('img/gtv_app_video.jpg')}></video>

                                        <div className="video_play blue_gradient hover_dark"
                                             onClick={this.handleVideo}></div>
                                    </div>
                                </div>
                                {/*<div className="ytWraper">*/}
                                {/*    <iframe unique="accelerometer" width="100%" src="https://www.youtube.com/embed/VYkCTPnoZLc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="subFooter contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="flexElem text-center flexResponsiveSM alignCenter">
                                    <div className='pollyBold'>
                                        Questions?
                                    </div>

                                    <div>
                                        <Link to="/Contact_Us" className="how_it_works_contact_us pollyBold">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="appCards">
                    <h2 className="wordpressHeading text-center pollyBold">The App Card</h2>
                    <div className="wordpressHeading subHeading text-center pollyBold">Important Info Your Group Will Need</div>

                    <div className="container">
                        <div className="row flexElem flexResponsive alignCenter justifyCenter">
                            <div className="col-md-6 mt-xlg">
                                <img src={s3StaticPath('img/works/app_card.png')} alt="app card"/>
                            </div>

                            <div className="col-md-6 mt-xlg">
                                <div className="appCardHeading">Your Group's Private Access</div>
                                <p>We will send you an Instructional 'App Card' that will tell each traveler exactly how
                                    to download &amp; access their private group. This <strong>key
                                        ingredient</strong> gives your travelers the information they need so their
                                    families can follow along from home. </p>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="how_to_order flexElem flexResponsive alignCenter justifyCenter">
                    <div className="">
                        <h2 className="wordpressHeading text-center pollyBold">
                            How to Order
                            <br/>&nbsp;Wedding TripKit&trade;</h2>

                        <div className="container">
                            <div className="row flexElem flexResponsive alignCenter">
                                <div className="col-md-10 col-md-offset-1 mt-xlg">
                                    <p>
                                        If you are a tour operator and want to order for your group, please <Link
                                        className="blue_link" to="/Login">click here</Link>. If you are a group
                                        leader, <Link className="blue_link" to="/Contact_Us">contact us</Link> and let
                                        us know if you are working with a Tour Operator. They may have already included
                                        Wedding TripKit™ in your travel package. If you are arranging the trip on
                                        your own, let us know and we can help you place an order for your group.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="inspire">
                    <div className="">
                        <div className="container">
                            <div className="row flexElem alignCenter">
                                <div className="col-xs-5 mt-xlg">
                                    <img src={s3StaticPath('img/works/raising_hands.jpeg')} alt="raising hands"/>
                                </div>
                                <div className="col-xs-7 mt-xlg">
                                    <h3 className="wordpressHeading pollyBold">Inspire Group Participation</h3>
                                    <p>
                                        Did we make a video for your group last year? If so, show the video at your
                                        pre-trip meetings to generate excitement &amp; participation. When they see
                                        people they know in the video, it will encourage trip enrollment. The video also
                                        helps reinforce the educational reason for the trip.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="benefits">
                    <h4 className="wordpressHeading text-center pollyBold">Everyone Benefits</h4>
                    <div className="">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-4">
                                    <div className="pr-lg pl-lg">
                                        <div className="mt-xlg"><img src={s3StaticPath('img/works/family_home.jpeg')}
                                                                     alt="family home"/></div>
                                        <div className="blockHeading pollyBold">
                                            Family at Home
                                        </div>
                                        <p>
                                            Log in with the group’s username and password your traveler has on their
                                            Instructional App Card using your smartphone, tablet, or computer. Enjoy
                                            seeing pictures of your loved ones learning, knowing they’re safe while
                                            creating great memories.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-xs-4">
                                    <div className="pr-lg pl-lg">
                                        <div className="mt-xlg"><img src={s3StaticPath('img/works/teacher_next.jpg')}
                                                                     alt="teacher next"/></div>
                                        <div className="blockHeading pollyBold">
                                            Teachers &amp; Students
                                        </div>
                                        <p>
                                            Teachers can show the previous year’s video to generate
                                            excitement &amp; participation. Students, you are about to learn and see
                                            things you may have only read about. Experiencing it in real life is
                                            something you will want to remember and relive for years to come.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-xs-4">
                                    <div className="pr-lg pl-lg">
                                        <div className="mt-xlg"><img src={s3StaticPath('img/works/adult_travel.png')}
                                                                     alt="adult travel"/></div>
                                        <div className="blockHeading pollyBold">
                                            Adult Travelers &amp; Group Leaders
                                        </div>
                                        <p>
                                            You are about to learn and see things you may have only read about.
                                            Experiencing it in real life is something you will want to remember and
                                            relive for years to come. Take lots of pictures! These will help you tell
                                            the story of your trip.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="faqs">
                    <div className="wordpressHeading text-center pollyBold">FREQUENTLY ASKED QUESTIONS</div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 pollyRegular">
                                <Faqlist
                                    quest="Do you edit the photos?"
                                    ans='Yes, a photo editor views every image. They delete photos that do not meet our quality standards, enhance photos by cropping, straightening, and color correction. We also watch for photos that might be objectionable.'
                                />

                                <Faqlist
                                    quest="Can I upload my digital camera photos to you online?"
                                    ans='Yes, simply <a href="/Login" class="blue_link">login to this website</a> with the same username and password shown on your app card. Follow the easy directions to add your photos. Be sure to add your name so it appears under the photo on the app and website so others will know it is your photo.'
                                />

                                <Faqlist
                                    quest="When will my group receive the Storytelling Video of the trip?"
                                    ans='Production time generally takes approximately 2-3 weeks from the time we receive your trip photos and all required materials from your Group Leader/Photo Admin. Shipping times vary by location.'
                                />

                                <Faqlist
                                    quest="Why can`t I see the photos that have been uploaded?"
                                    ans='The Photo Administrator for your group may have selected to require pre-approval of the photos prior to posting in your group’s gallery.'
                                />

                                <Faqlist
                                    quest="What size and resolution do my photos need to be?"
                                    ans='Our uploader is designed to resize your images to match our production process. It is best that the pixel height or width be no smaller than 1200. We can still accept images that are smaller, but if the width or height is under 480, it will start to lower the quality of the video.'
                                />

                                <Faqlist
                                    quest="How do I delete a photo I uploaded?"
                                    ans='Travelers can only delete photos that they uploaded on their own. However, Photo Administrator has the ability to monitor and delete all group photos before submitting for production.'
                                />

                                <Faqlist
                                    quest="Can I have access to the photos used in the video?"
                                    ans='Yes, once the photos are edited, they are posted to this secure website
                                and on the app. Access it with your group’s username and password
                                found on your ‘app card’. These will be displayed at the beginning and
                                end of your video, too. You can review and download the photos for
                                free from the photo gallery on the app or our website. <a href="/Login" class="blue_link">Login here.</a> You can
                                also order a CD of all the images of the highest resolution photos uploaded and save time downloading them all.'
                                />

                                <Faqlist
                                    quest="Do you offer any other products made from my group's pictures?"
                                    ans='Yes, you may order a cd with all of the photos taken on your tour as well as a 20″x30″ PhotoMozaix™. To learn more about PhotoMozaix, visit <a target="_blank" href="https://www.weddingtripkit.com/mozaix">www.weddingtripkit.com/mozaix</a>'
                                />

                                <Faqlist
                                    quest="What about privacy? Is the photo and video access private?"
                                    ans='Access to your photos and Wedding TripKit is password protected, so only those who go on the trip and their family can have access to them. We do not ask for, nor need, the user’s email address. That is why there is a username and password to access the app.
                                <br /><br />
                                No private information is gathered from the user’s app device. Privacy is very important to us and we take it very seriously.
                                <br /><br />
                                No third party receives any information about you, because in fact, there is no information to share. So, your privacy is secure when using our app or website.
                                <br /><br />
                                If you have a traveler who does not want their photo taken, it is their responsibility to let people know this.
                                <br /><br />
                                The app does have a convenient social media sharing feature that allows users to post photos to Facebook, Twitter, and Instagram. Your group has the option to have all uploaded photos approved for viewing before they can be seen on the app or website.
                                <br /><br />
                                The Approve for Viewing can only be done by logging into the website as the Group Leader/Photo Administrator.'
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ourClients">
                    <div className="text-center">
                        <a href="#" className="link  pollyRegular">We work with over 200 Tour Operators</a>
                    </div>
                    <div className="text-center pollyBold">
                        and are proud members of
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="flexElem">
                                    <div><img className="mt-lg" src={s3StaticPath('img/home/nta-transparent.png')}
                                              alt="NTA"/></div>
                                    <div><img className="mt-lg" src={s3StaticPath('img/home/SYTA-Transparent.png')}
                                              alt="SYTA"/></div>
                                    <div><img className="mt-lg" src={s3StaticPath('img/home/ABA-Transparent.png')}
                                              alt="ABA"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="subFooter">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="flexElem text-center flexResponsive alignCenter pollyRegular">
                                    <div>
                                        Are you a Tour Operator?
                                    </div>

                                    <div>
                                        <Link to="/gtv-tour-operators" className="how_it_works_contact_us">
                                            See Our Partner Page
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default HowWorks;
