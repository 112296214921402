import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from "components/Card/Card.jsx";
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import CompanyInfoForm from 'components/FrontEnd/Account/Company/CompanyInfoForm'
import { getAgency } from 'redux/actions/agency-actions';
import User from 'helper/User';
import MarketingFlyers from 'components/FrontEnd/Account/MarketingFlyers'
import { helperFunctions as HF } from 'helper/helperFunctions';
// import MoreButtonsList from '../MoreButton/MoreButtonsList'
import AgencyLogo from 'components/ThemeComponents/AgencyLogo';
import UsersList from 'components/FrontEnd/Account/Company/UsersList'
import { Roles } from '../../../constants/Roles';
import AgencyUserForm from '../../Admin/AgencyUsers/AgencyUserForm';
import { getAgencyRoles } from '../../../redux/actions/role-actions';
import { getAgencyUser, editAgencyUser } from '../../../redux/actions/agency-user-actions';
import MoreButtonsList from '../MoreButton/MoreButtonsList';
import { Collapse } from 'react-bootstrap';
import AgencyDocuments from './AgencyDocuments';
import { BsExclamationTriangle } from 'react-icons/bs';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
      user: {
        name: '',
        username: '',
        fax: '',
        phone1: '',
        phone2: '',
        email: '',
        password: '',
        confirm_password: '',
        agencyRoles: '',
        is_active: ''
      }
    }
  }

  componentDidMount() {
    this.props.getAgencyCall(User.agency('id'));
    if (!(User.roleExists(Roles.AGENCY_USER) || User.roleExists(Roles.AGENCY_USER_U)) && User.roleExists(Roles.AGENCY_USER_SC)) {
      this.props.getAllRolesCall();
      this.props.getAgencyUserCall(User.getProperty('agency.id'), User.getProperty('id'))
    }
  }


  submit = () => {
    this.setState({
      submit: true
    }, () => {
      this.setState({
        submit: false
      })
    })
  }

  userFormSubmitHandler = (user, formType) => {
    const data = {
      page: 1,
      filter: '',
      sort: '',
      pageSize: 10
    }
    if (formType === 'edit') {
      this.props.editAgencyUserCall(user, this.props.editAgencyUser.id, User.agency('id')).then(
        response => {
          // if (!this.pagination) {
          //     this.paginationCall(data);
          // } else {
          //     this.pagination.dataCall();
          // }
          // this.setState(
          //     editUserModal: false
          // })
        }
      );
    } else if (formType === 'add') {
      this.props.storeAgencyUserCall(User.agency('id'), user).then(
        response => {
          this.setState({
            createUserModal: false
          }, () => {
            if (!this.pagination) {
              this.paginationCall(data)
            }
          })
          return response
        },
        err => {
          return err
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editAgencyUser !== this.props.editAgencyUser && !this.props.loadingAgencyUser) {
      this.setState({
        user: {
          name: this.props.editAgencyUser.name,
          username: this.props.editAgencyUser.username,
          fax: this.props.editAgencyUser.contact !== null ? this.props.editAgencyUser.contact.fax === null ? '' : this.props.editAgencyUser.contact.fax : '',
          phone1: this.props.editAgencyUser.contact ? this.props.editAgencyUser.contact.phone1 : '',
          phone2: this.props.editAgencyUser.contact ? this.props.editAgencyUser.contact.phone2 === null ? '' : this.props.editAgencyUser.contact.phone2 : '',
          email: this.props.editAgencyUser.email,
          password: '',
          confirm_password: '',
          agencyRoles: this.props.editAgencyUser.agencyRoles,
          is_active: this.props.editAgencyUser.is_active
        }
      })
    }
  }

  render() {
    const { agencyRoles, columns } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="panel_heading userLogin_heading">Your Account</h2>
          </div>
        </div>
        {
          (!(User.roleExists(Roles.AGENCY_USER) || User.roleExists(Roles.AGENCY_USER_U)) && User.roleExists(Roles.AGENCY_USER_SC)) ?
            <div className='row mb-xlg'>
              <div className='col-md-6 col-md-offset-3'>
                <div className="form_tile">
                  <div className="form_tile_heading">
                    <img src={HF.s3StaticPath('img/account_users.png')} alt="User Information" />
                    <span>User Information</span>
                  </div>
                  <div className="form_tile_content">
                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                      <AgencyUserForm
                        formTypePro="edit"
                        userPro={this.state.user}
                        handleAgencyUserSubmit={this.userFormSubmitHandler}
                        componentPro="adminAgencyUser"
                        isSalesConsultant="yes"
                        agencyRoles={agencyRoles || []}
                        frontEnd={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="row mb-xlg">
              <div className="col-md-6">
                <CompanyInfoForm submit={this.state.submit} agency={this.props.editAgency} />
              </div>

              <div className="col-md-6">
                <UsersList />
                <div className="form_tile mb-2">
                  <div className="form_tile_heading">
                    <img src={HF.s3StaticPath('img/video_titles.png')} alt="Video Title" />
                    <span>Company Logo</span>
                  </div>
                  <div className="form_tile_content">
                    <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                      <div className="col-md-5 text_left_center_991">
                        <AgencyLogo />
                      </div>
                      <div className="col-md-7">
                        <div className="text_14"><strong className="theme_color">NOTE:</strong> This is the logo we currently have in our system to use for your branding. If this logo is outdated or not the logo your company currently uses, please email your new logo to <a href="mailto:My@WeddingTripKit.com" className="text-primary link_hover">My@WeddingTripKit.com</a></div>
                      </div>
                    </div>
                  </div>
                </div>
                <AgencyDocuments
                  agency_id={User.agency('id')}
                  agency={this.props.editAgency}
                  formType={'edit'}
                />
                <MoreButtonsList
                  columns={columns}
                  agency_id={User.agency('id')}
                  agency={this.props.editAgency} />
                <MarketingFlyers />
              </div>

              <div className="col-md-4 col-md-offset-4">
                <button onClick={this.submit} className="themeBtn_14 block mt-xlg mb-xlg blue_theme_button hover_dark">Save</button>
              </div>
            </div>
        }

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { agencyRoles } = state.rolesReducer;
  const { editAgency } = state.agenciesReducer;
  const { editAgencyUser, updatedAgencyUser, current_page, loadingAgencyUser, deleteAgencyUser } = state.agencyUserReducer;
  return {
    editAgencyUser,
    updatedAgencyUser,
    current_page,
    loadingAgencyUser,
    deleteAgencyUser,
    editAgency,
    agencyRoles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAgencyCall: (agencyId) => { dispatch(getAgency(agencyId)) },
    getAllRolesCall: () => { dispatch(getAgencyRoles()) },
    getAgencyUserCall: (agency_id, id) => { dispatch(getAgencyUser(agency_id, id)) },
    editAgencyUserCall: (userData, id, agency_id) => { return dispatch(editAgencyUser(userData, id, agency_id)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(Account))
