import React, { Component } from 'react';
// import footer_logo from "assets/img/footer_logo.png";
import Logo from 'components/FrontEnd/Brand/Logo';
import { Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom";
import { hasRole } from 'helper/helperFunctions';
import FooterLogo from 'assets/wkt/logo-white.svg';
import facebook from 'assets/wkt/facebook.svg';
import linkedin from 'assets/wkt/linkedin.svg';
import twitter from 'assets/wkt/twitter.svg';
import instagram from 'assets/wkt/instagram.svg';
import youtube from 'assets/wkt/youtube.svg';
import phoneIcon from 'assets/wkt/phone-icon.svg';
import mailIcon from 'assets/wkt/phenvelope-fill.svg';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: ''
    }
    this.fbLike = this.fbLike.bind(this);
  }

  fbLike() {
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.0";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  componentDidMount() {
    this.fbLike();
  }

  render() {
    return (
      <footer id="footer" className='weddingFooter'>
        <div className='container'>
          <Row>
            <Col md={4}>
              <Link to="/">
                <img src={FooterLogo} alt={FooterLogo} />
              </Link>
            </Col>
            <Col md={4}>
              <h3 className='pollyBold textWhite'>Contact</h3>
              <ul className='contact'>
                <li><img src={mailIcon} alt={mailIcon} width={14} /> My@WeddingTripKit.com</li>
                <li><img src={phoneIcon} alt={phoneIcon} width={14} /> Call: 888.533.7637 x213</li>
              </ul>
            </Col>
            <Col md={4}>
              <ul className='socialLinks'>
                <li><Link to={"/"}><img src={facebook} alt={facebook} /></Link></li>
                <li><Link to={"/"}><img src={youtube} alt={youtube} /></Link></li>
                <li><Link to={"/"}><img src={instagram} alt={instagram} /></Link></li>
                <li><Link to={"/"}><img src={linkedin} alt={linkedin} /></Link></li>
                <li><Link to={"/"}><img src={twitter} alt={twitter} /></Link></li>
              </ul>
              <p className='textWhite'>Wedding Trip Kit has been serving and creating memories since 1997, specifically the Weddings</p>
            </Col>
          </Row>
        </div>
        <div className="footerRight">
          <div className='container'>
            <Row>
              <Col md={6}>© 2024 Wedding Trip Kit™</Col>
              <Col md={6}>
                <ul className='footerLinks'>
                  <li><Link to={"/Contact_Us"}>Contact us</Link></li>
                  <li><Link to={"/Terms_and_Conditions"}>Terms & Conditions</Link></li>
                  <li><Link to={"/Privacy_Policy"}>Privacy Policy</Link></li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
